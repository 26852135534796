import { Form, Formik } from "formik";
import { validationForm } from "./ValidationForm";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomTextField from "../CustomFields/CustomTextField";
import {
  CP_INVALIDO,
  EDAD_MUJER_MAX,
  EDAD_MUJER_MIN,
  FECHA_VALIDA,
  INTER_CONTRATANTE,
  MAX_LENGTH_CP,
  MAYOR_EDAD,
} from "../Utils/Constantes";
import { useEffect, useState } from "react";
import CustomDatePickerLab from "../CustomFields/CustomDatePickerLab";
import {
  calcularEdad,
  generoValueToService,
  maxLength,
  serviceToGeneroValue,
  validaMayorEdad,
  validaResponse,
} from "../Utils/Utils";
import CustomErrorMessage from "../CustomFields/CustomErrorMessage";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Api from "../../components/Api/Api";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Dialog, DialogContent } from "@mui/material";
import BlueTooltip from "../Utils/BlueTooltip";
import Loading from "../Commons/Loader";
import { useScoreLead } from "../../hooks/useScoreLead";

export default function Datos(props) {
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [datos, setDatos] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [pasoGenero, setPasoGenero] = useState(false);
  const [pasoCP, setPasoCP] = useState(false);
  const [pasoEmbarazo, setPasoEmbarazo] = useState(false);
  const [cotizar, setCotizar] = useState(false);
  const [openDatosDialog, setOpenDatosDialog] = useState(false);
  const { device, scoreLead, tipoSeguro } = useScoreLead();

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    setCotizar(false);
    setFieldValue("genero", null);
    setPasoGenero(false);
    setFieldValue("embarazo", null);
    setPasoEmbarazo(false);
    setFieldValue("cp", "");
    setPasoCP(false);
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha);
      if (validaMayorEdad(fecha)) {
        setPasoGenero(true);
      } else {
        setFieldError("fechanacimiento", MAYOR_EDAD);
      }
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA);
    }
  };

  const handleGenero = (genero, setFieldValue, values) => {
    setFieldValue("embarazo", null);
    setFieldValue("cp", "");
    setPasoEmbarazo(false);
    setPasoCP(false);
    setFieldValue("genero", genero);
    if (genero === 0) {
      setPasoCP(true);
      setFieldValue("cp", "");
      setPasoEmbarazo(false);
    } else {
      //validacion pregunta embarazo
      if (
        calcularEdad(values.fechanacimiento) > EDAD_MUJER_MIN &&
        calcularEdad(values.fechanacimiento) < EDAD_MUJER_MAX
      ) {
        setPasoEmbarazo(true);
      } else {
        setPasoCP(true);
      }
    }
  };

  const handleEmbarazo = (embarazo, setFieldValue) => {
    setFieldValue("embarazo", embarazo);
    setPasoCP(true);
  };

  const handleChangeCP = (event, setFieldValue, setFieldError) => {
    const cp = maxLength(event.target.value, MAX_LENGTH_CP);
    setFieldValue("cp", cp);
    if (cp.length === MAX_LENGTH_CP) {
      Api.validaCP(cp)
        .then((res) => {
          if (validaResponse(res) && res.data.existe) {
            setCotizar(true);
          } else {
            setFieldError("cp", CP_INVALIDO);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCotizar(false);
    }
  };

  const handleContinuar = (values, setSubmitting) => {
    let contratante = {},
      aseguradoTitular = {};
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      contratante = JSON.parse(
        window.sessionStorage.getItem(INTER_CONTRATANTE)
      );
      contratante.fechanacimiento = values.fechanacimiento;
      contratante.genero = generoValueToService(values.genero);
      contratante.embarazo = values.embarazo;
      contratante.cp = values.cp;
      contratante.ultimoPaso = 1;
      contratante.statusSegurify = 1;
      contratante.sexo = generoValueToService(values.genero);
      aseguradoTitular.fechanacimiento = values.fechanacimiento;
      aseguradoTitular.genero = generoValueToService(values.genero);
      aseguradoTitular.embarazo = values.embarazo;
      aseguradoTitular.cp = values.cp;
      aseguradoTitular.nombre = contratante.nombre;
      aseguradoTitular.telefono = contratante.telefono;
      aseguradoTitular.correo = contratante.correo;
      aseguradoTitular.titular = true;
      aseguradoTitular.indice = 0;
      aseguradoTitular.id = values.id;
      //aseguradoTitular.parentesco = "Titular";

      window.dataLayer.push({
        event: "form_step_2_submit",
        genero: generoValueToService(values.genero),
        CP: values.cp,
      });

      window.dataLayer.push({
        event: "medico_seguro_demograficos",
        fecha_nacimiento: values.fechanacimiento,
        sexo: values.genero,
        cp: values.cp,
        cotizacion: contratante.folio,
        boton_demograficos: "Cotizar",
      });

      contratante.asegurados = [].concat(aseguradoTitular);
      window.sessionStorage.setItem(
        INTER_CONTRATANTE,
        JSON.stringify({
          ...contratante,
          dispositivo: device,
          tipoSeguro,
          scoreLead,
        })
      );
      if (calcularEdad(new Date(values.fechanacimiento)) > 70) {
        props.handleAdvertencias(1, true);
      } else if (values.genero === 1 && values.embarazo) {
        props.handleAdvertencias(2, true);
      } else {
        Api.actualizaContratante(contratante.id, {...contratante, dispositivo: device, tipoSeguro, scoreLead})
          .then((res) => {
            Api.getContratante(contratante.folio)
              .then((res) => {
                setOpenLoader(false);
                window.sessionStorage.setItem(
                  INTER_CONTRATANTE,
                  JSON.stringify({
                    ...res.data.contratante,
                    dispositivo: device,
                    tipoSeguro,
                    scoreLead,
                  })
                );
                setSubmitting(false);
                switch (contratante.tipoFlujo) {
                  case 1:
                    //para mi
                    navigate("/cotizacion");
                    break;
                  case 2:
                  case 3:
                    //pm y am
                    navigate("/datos/alguien-mas");
                    break;
                  default:
                    console.error("Tipo flujo no identificado");
                    break;
                }
              })
              .catch((error) => {
                setOpenLoader(false);
                setSubmitting(false);
                console.log(error);
              });
          })
          .catch((error) => {
            setOpenLoader(false);
            setSubmitting(false);
            console.log(error);
          });
      }
    }
  };

  const getEmbarazoTitular = () => {
    const contratante = JSON.parse(
      window.sessionStorage.getItem(INTER_CONTRATANTE)
    );
    if (contratante.asegurados !== null && contratante.asegurados.length > 0) {
      const currentTitular = contratante.asegurados.find(
        (asegurado) => asegurado.titular === true
      );
      return currentTitular !== null &&
        currentTitular !== undefined &&
        currentTitular.embarazo !== null
        ? currentTitular.embarazo
        : null;
    } else {
      return null;
    }
  };

  const getIdTitular = () => {
    const contratante = JSON.parse(
      window.sessionStorage.getItem(INTER_CONTRATANTE)
    );
    if (contratante.asegurados !== null && contratante.asegurados.length > 0) {
      const currentTitular = contratante.asegurados.find(
        (asegurado) => asegurado.titular === true
      );
      return currentTitular !== null &&
        currentTitular !== undefined &&
        currentTitular.id !== null
        ? currentTitular.id
        : null;
    } else {
      return null;
    }
  };

  const handleClickOpen = () => {
    setOpenDatosDialog(true);
  };

  const handleClickClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDatosDialog(false);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(
        window.sessionStorage.getItem(INTER_CONTRATANTE)
      );
      if (contratante.fechanacimiento) {
        setDatos(contratante);
        setCotizar(true);
        setPasoGenero(true);
        if (serviceToGeneroValue(contratante.genero) === 1) {
          if (
            calcularEdad(new Date(contratante.fechanacimiento)) >
              EDAD_MUJER_MIN &&
            calcularEdad(new Date(contratante.fechanacimiento)) < EDAD_MUJER_MAX
          ) {
            setPasoEmbarazo(true);
          }
        }
        setPasoCP(true);
      }
      setLoading(false);
    }
    /* Especifica cómo sanear este efecto:
		return function cleanup() {
		};*/
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "form_step_2_view",
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {!props.isMobile && (
            <IconButton className="back-button" onClick={props.handleRegresar}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box className="box-perfilamiento-form">
            <Formik
              validateOnChange={true}
              initialValues={{
                fechanacimiento:
                  datos && datos.fechanacimiento
                    ? new Date(datos.fechanacimiento)
                    : new Date(),
                genero:
                  datos && datos.genero
                    ? serviceToGeneroValue(datos.genero)
                    : null,
                embarazo: getEmbarazoTitular(),
                cp: datos && datos.cp ? datos.cp : null,
                id: getIdTitular(),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setOpenLoader(true);
                handleContinuar(values, setSubmitting);
              }}
              validationSchema={validationForm}
            >
              {({
                values,
                submitForm,
                setFieldValue,
                isSubmitting,
                setFieldError,
              }) => (
                <Form>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="normalize-modal"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="w-100"
                      sx={{ paddingTop: "0% !important" }}
                    >
                      <p className="datos-txt">
                        Ahora nos gustar&iacute;a saber un poco m&aacute;s de
                        ti.
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="w-100"
                      sx={{ paddingTop: "0% !important" }}
                    >
                      <p>¿Cu&aacute;ndo naciste?</p>
                      <CustomDatePickerLab
                        id="fechanacimiento"
                        name="fechanacimiento"
                        type="text"
                        placeholder="Fecha Nacimiento"
                        className="grid-item-input w-100"
                        disableFuture
                        onChange={(newValue) =>
                          handleChangeFechaNacimiento(
                            newValue,
                            setFieldValue,
                            setFieldError
                          )
                        }
                      />
                    </Grid>

                    {pasoGenero && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="w-100"
                        sx={{ paddingTop: "0% !important" }}
                      >
                        <p className="p-genero-tooltip">
                          ¿C&uacute;al es tu sexo de nacimiento?
                          <BlueTooltip title="Sabemos que esta pregunta puede ser complicada, pero para las aseguradora es un dato muy importante para saber cómo protegerte."></BlueTooltip>
                        </p>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="end"
                        >
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              variant="outlined"
                              disableElevation
                              className={clsx(
                                {
                                  "boton-form-seleccionado":
                                    values.genero === 0,
                                },
                                { "boton-form-datos": true }
                              )}
                              onClick={(event) =>
                                handleGenero(0, setFieldValue, values)
                              }
                            >
                              Hombre
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            className="div-boton-derecho"
                          >
                            <Button
                              variant="outlined"
                              disableElevation
                              className={clsx(
                                {
                                  "boton-form-seleccionado":
                                    values.genero === 1,
                                },
                                { "boton-form-datos": true }
                              )}
                              onClick={(event) =>
                                handleGenero(1, setFieldValue, values)
                              }
                            >
                              Mujer
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {pasoEmbarazo && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="w-100"
                      >
                        <p className="mX-10">¿Est&aacute;s embarazada?</p>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button
                              variant="outlined"
                              disableElevation
                              className={clsx(
                                { "boton-form-seleccionado": values.embarazo },
                                { "boton-form-datos": true }
                              )}
                              onClick={(event) =>
                                handleEmbarazo(true, setFieldValue)
                              }
                            >
                              Si
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            className="div-boton-derecho"
                          >
                            <Button
                              variant="outlined"
                              disableElevation
                              className={clsx(
                                {
                                  "boton-form-seleccionado":
                                    values.embarazo === false,
                                },
                                { "boton-form-datos": true }
                              )}
                              onClick={(event) =>
                                handleEmbarazo(false, setFieldValue)
                              }
                            >
                              No
                            </Button>
                          </Grid>
                          <CustomErrorMessage name="embarazo" />
                        </Grid>
                      </Grid>
                    )}
                    {pasoCP && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="w-100"
                        sx={{ paddingTop: "0% !important" }}
                      >
                        <p className="fecha-nacimiento">
                          ¿C&uacute;al es tu c&oacute;digo postal?
                        </p>
                        <CustomTextField
                          id="cp"
                          name="cp"
                          type="number"
                          placeholder="C.P."
                          className="grid-item-input"
                          onChange={(event) =>
                            handleChangeCP(event, setFieldValue, setFieldError)
                          }
                        />
                        <Box className="texto-pedimos-datos">
                          <p
                            className="texto-pedimos-datos-cursor"
                            onClick={handleClickOpen}
                          >
                            ¿Por qu&eacute; pedimos estos datos?
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {cotizar && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          variant="outlined"
                          disableElevation
                          className="boton-submit boton-form-seleccionado"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          COTIZAR
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
          <Dialog
            open={openDatosDialog}
            onClose={(event, reason) => handleClickClose(event, reason)}
            disableEscapeKeyDown={true}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogContent className="bg-dialog-datos">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box className="texto-dialog-datos">
                    A veces el costo de un seguro puede variar dependiendo de
                    los datos de los asegurados. Esto es algo que determina cada
                    aseguradora.
                    <br />
                    <br />
                    En Inter queremos ser cada vez m&aacute;s inclusivos, por
                    eso estamos trabajando para cambiar las reglas de las
                    aseguradoras.
                    <br />
                    <br />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box className="texto-dialog-aviso-datos">
                    En Inter al igual que a ti, nos encanta la privacidad
                    <br />
                    Consulta nuestro{" "}
                    <a
                      className="a-dialog-aviso-datos"
                      target="_blank"
                      href="https://inter.mx/aviso-de-privacidad"
                      rel="noreferrer"
                    >
                      Aviso de privacidad
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <br />
                  <Button
                    variant="outlined"
                    disableElevation
                    className="boton-submit boton-dialog-aviso-datos"
                    onClick={handleClickClose}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
        </>
      )}
    </>
  );
}
