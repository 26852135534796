import React, { useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Form, Formik } from "formik";
import Loading from "../../Commons/Loader";
import { useNavigate } from "react-router-dom";
import Api from "../../Api/Api";
import { INTER_CONTRATANTE, TIPO_FLUJO } from "../../Utils/Constantes";
import { validationFormDos } from "../ValidationForm";
import CustomTextField from "../../CustomFields/CustomTextField";
import { useScoreLead } from "../../../hooks/useScoreLead";

export default function FormHaveQuotationNumber(props) {
  const { setFormVisibility } = props;
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = React.useState(false);
  const {device, tipoSeguro, scoreLead} = useScoreLead()

  const galloMediaStep = ({correo, num: cotizacion}) => {
    window.dataLayer.push({
      'event': "form_step_0_yaTengoCot_submit",
      'eventCategory': 'Formulario.Seguro.GMM.v2',
      'eventAction': 'step_0_yaTengoCot_submit',
      'eventLabel': 'yaTengoCotizacion',
      'noCotizacion': cotizacion || undefined,
      'correo': correo || undefined
    })
  }

  const galloMediaOnView = () => {
    window.dataLayer.push({ 
      'event': 'form_step_0_yaTengoCot_view',
      'eventCategory': 'Formulario.Seguro.GMM.v2',
      'eventAction': 'step_0_yaTengoCot_view',
      'eventLabel': 'yaTengoCotizacion',
      'noCotizacion': undefined,
      'correo': undefined
     });
     
  }

  useEffect(()=>{
    galloMediaOnView()
  },[])

  const handleListo = (values, setSubmitting, setFieldError) => {
    const datosCotizacion = {
      correo: values.correo,
      num: values.num,
    };
    galloMediaStep(datosCotizacion)
    window.sessionStorage.setItem(
      "FormDosInterGMM",
      JSON.stringify(datosCotizacion)
    );
    window.dataLayer.push({
      event: "medico_seguro_contacto",
      tipo_cotizacion: "Ya tengo una cotizacion",
      cotizacion: datosCotizacion.num,
      boton_contacto: "Seguir cotizando"
    })
    setSubmitting(false);
    Api.getContratante(datosCotizacion.num)
      .then((res) => {
        setOpenLoader(false);
        if (
          String(res.data.contratante.correo).toLowerCase() ===
          String(values.correo).toLowerCase()
        ) {
          sessionStorage.setItem(
            INTER_CONTRATANTE,
            JSON.stringify({...res.data.contratante, dispositivo: device, tipoSeguro, scoreLead})
          );
          const tipoFlujo = res.data.contratante.tipoFlujo;
          if (tipoFlujo !== null) {
            sessionStorage.setItem(TIPO_FLUJO, tipoFlujo);
          }
          switch (res.data.contratante.ultimoPaso) {
            case 1:
              navigate("/datos-personales");
              break;
            case 2:
              if (tipoFlujo === 3) {
                navigate("/datos/alguien-mas");
              } else {
                navigate("/datos/alguien-mas/agregar-mas");
              }
              break;
            case 3:
              navigate("/detalles-cotizacion");
              break;
            case 4:
            case 5:
            case 6:
            case 7:
              navigate("/cuestionario");
              break;
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
              navigate("/contratacion");
              break;
            case 15:
              navigate("/terminamos-contratacion");
              break;
            default:
              navigate("/Bienvenida");
              break;
          }
        } else {
          setFieldError(
            "correo",
            "Alguno de los valores ingresados nos es correcto"
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <>
      <Formik
	  	validateOnChange={true}
		initialValues={{
			num: "",
			correo: ""
		}}
		onSubmit={(values, {setSubmitting, setFieldError}) => {
			setSubmitting(true)
			setOpenLoader(true)
			handleListo(values, setSubmitting, setFieldError)
		}}
		validationSchema={validationFormDos}
	  >
        {({submitForm, isSubmitting}) => (
          <Form>
            <Grid
              container
              sx={{
                padding: "24px clamp(16px, 2.5svw, 37px)",
                width: "clamp(312px, 60vw, 468px)",
                rowGap: "24px",
              }}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  ¡Qué cool que estés de regreso!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "8px",
                  }}
                >
                  ¿Cuál es tu número de cotización
                </Typography>
                <CustomTextField
                  id="num"
                  name="num"
                  type="text"
                  placeholder="No. de cotización"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "8px",
                  }}
                >
                  Y ¿tu correo electrónico?
                </Typography>
                <CustomTextField
                  id="correo"
                  name="correo"
                  type="text"
                  placeholder="Correo"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#039ECC",
                    textDecoration: "underline",
                    fontWeight: "400",
                    cursor: "pointer",
                  }}
                  onClick={() => setFormVisibility(true)}
                >
                  ¿No tienes número de cotización?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={submitForm}
                  disabled={isSubmitting}
                  disableElevation
                  fullWidth
                  endIcon={<ArrowForwardIcon />}
                  variant={"contained"}
                  sx={{ height: "56px" }}
                >
                  Seguir cotizando
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </>
  );
}
