import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import LogoInter from '../../assets/img/footer/LogoInter-w.svg';
import LogoApple from '../../assets/img/footer/Apple-gray.svg';
import LogoGoogle from '../../assets/img/footer/Google-gray.svg';
import LFace from '../../assets/img/footer/LFacebook.svg';
import LInst from '../../assets/img/footer/LInstagram.svg';
import LIn from '../../assets/img/footer/LLinkedIn.svg';
import LYou from '../../assets/img/footer/LYoutube.svg';
import { useLocation } from 'react-router-dom';

export default function Footer(props) {
  const location = useLocation();
  const [isCRM, setIsCRM] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/crm")) {
      setIsCRM(true);
    } else {
      setIsCRM(false);
    }
  }, [location.pathname]);

  return (
    <>
      { !isCRM &&
      <div className="footer">
        <Container maxWidth={'xl'}>
          <Grid container spacing={4} marginTop="0">
            <Grid item xs={12} md={3}>
              <Box
                component={'img'}
                src={LogoInter}
                sx={{
                  width: { xs: 180, md: 160 },
                  maxWidth: { xs: 180, md: 180 },
                }}
                alt={'Inter, seguro, seguro'}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  variant={'subtitle1'}
                  color={'white'}
                  fontWeight={'bold'}
                >
                  {'Productos'}
                </Typography>
                <Box marginBottom={3} />
                <Link
                    href={'https://cocheseguro.inter.mx/inicio'}
                    target="_blank"
                    variant={'subtitle1'}
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >
                    Coche Seguro
                  </Link>
                  <Link
                    href={'https://mascotasegura.inter.mx/seguro-mascota/landing'}
                    target="_blank"
                    variant={'subtitle1'}
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >
                    Mascota Segura
                  </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  variant={'subtitle1'}
                  color={'white'}
                  fontWeight={'bold'}
                  style={{ cursor: 'pointer' }}
                >
                  {'Contáctanos'}
                </Typography>
                <Box marginBottom={2} />
                <Link
                    href={`tel:55 442 46837`}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      variant={'subtitle1'}
                      color={'white'}
                      style={{ cursor: 'pointer' }}
                    >
                      55 442 46837
                    </Typography>
                  </Link>
                  <Link
                    href={`mailto:hola@inter.mx`}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      variant={'subtitle1'}
                      color={'white'}
                      style={{ cursor: 'pointer' }}
                    >
                      hola@inter.mx
                    </Typography>
                  </Link>
                <Box marginBottom={4} />
                <Typography
                  variant={'subtitle1'}
                  color={'white'}
                  fontWeight={'bold'}
                >
                  {'Clientes'}
                </Typography>
                <Box marginBottom={2} />
                <Link
                    href={'https://www.interbi.mx/qlikview/FormLogin.htm'}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                  <Typography
                    variant={'subtitle1'}
                    color={'white'}
                  >
                    Login
                  </Typography>
                </Link>
                <Link
                    href={'https://www.interbi.mx/qlikview/FormLogin.htm'}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                  <Typography
                    variant={'subtitle1'}
                    color={'white'}
                  >
                    Aviso de privacidad
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  variant={'subtitle1'}
                  color={'white'}
                  fontWeight={'bold'}
                >
                  {'Descarga nuestra app'}
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={2}
                >
                  <Link
                    href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en"
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                    <img src={LogoApple} alt='LogoApple' />
                  </Link>
                  <Box marginLeft={0.8} />
                  <Link
                    href="https://play.google.com/store/apps/details?id=grut.inter.mx"
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                  >
                    <img src={LogoGoogle} alt='LogoGoogle' />
                  </Link>
                </Box>
                <Box marginBottom={4} />
                <Typography
                  variant={'subtitle1'}
                  fontWeight={'bold'}
                  color={'white'}
                >
                  {'Síguenos en redes sociales'}
                </Typography>
                <Box marginTop={'10px'}>
                  <Link href="https://www.facebook.com/intermx" target={'_blank'}>
                    <img 
                      src={LFace} 
                      alt='Facebook'
                      style={{
                        color: 'grey',
                        marginRight: '28.06px',
                        cursor: 'pointer',
                      }} 
                    />
                  </Link>
                  <Link href="https://www.instagram.com/intermx/" target={'_blank'}>
                    <img 
                      src={LInst} 
                      alt='Instagram'
                      style={{
                        color: 'grey',
                        marginRight: '28.06px',
                        cursor: 'pointer',
                      }} 
                    />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/interproteccion/"
                    target={'_blank'}
                  >
                    <img 
                      src={LIn} 
                      alt='Linkedin'
                      style={{
                        color: 'grey',
                        marginRight: '28.06px',
                        cursor: 'pointer',
                      }} 
                    />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA/videos"
                    target={'_blank'}
                  >
                    <img 
                      src={LYou} 
                      alt='Youtube'
                      style={{
                        color: 'grey',
                        cursor: 'pointer',
                      }} 
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box paddingY={2} />
          <Box
            paddingY={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              textAlign={'center'}
              variant={'caption'}
              color={'white'}
              style={{ fontWeight: 300 }}
            >
              {'Copyright 2021 ® Interprotección'}
            </Typography>
          </Box>
        </Container>
      </div>
      }
    </>
  );
}