import {useState} from 'react'
import { Box, Button, Grid, MenuItem } from "@mui/material";
import CustomRadio from "../../CustomFields/CustomRadio";
import CustomRadioGroup from "../../CustomFields/CustomRadioGroup";
import CustomSelect from "../../CustomFields/CustomSelect";
import { CMB_MENOS_UNO, meses } from "../../Utils/Constantes";
import { fillYears } from "../../Utils/Utils";
import { Formik } from "formik";
import { validationPadecimiento } from "../ValidationForm";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

export default function Padecimiento(props) {
  const anios = fillYears();
  const [totalSaved, setTotalSaved] = useState(props.cantidadCongenitos)
  const handleOnChangeValue = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleOnChangeCombo = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleGuardar = (values, setSubmitting, resetForm) => {
    let valuesItem = values;
    if(props.padecimientoItem !== null){
      valuesItem = {
        item: props.padecimientoItem,
        descripcion: values.descripcion,
        mes: values.mes,
        anio: values.anio,
        saludActual: values.saludActual,
        id: values.id,
      }
    }
    if(props.padecimiento === null){
      resetForm();
    }
    setSubmitting(false);
    setTotalSaved(totalSaved + 1)
    props.handleGuardar(valuesItem);
    props.setAlreadySaveAilling(true);
  };

  return (
    <Formik validateOnChange={true}
      initialValues={{
        item: (props.padecimiento ? props.padecimiento.item : null),
        id: (props.padecimiento ? props.padecimiento.id : null),
        descripcion: (props.padecimiento ? props.padecimiento.descripcion : CMB_MENOS_UNO),
        mes: (props.padecimiento ? props.padecimiento.mes : CMB_MENOS_UNO),
        anio: (props.padecimiento ? props.padecimiento.anio : CMB_MENOS_UNO),
        saludActual: (props.padecimiento ? props.padecimiento.saludActual : ""),
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleGuardar(values, setSubmitting, resetForm);
      }}
      validationSchema={validationPadecimiento}>
      {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
        <>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
            className='background-form-acordeon'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <span>¿Qu&eacute; fue?</span>
              <Box sx={{ mt: 2 }}>
                <CustomSelect id="descripcion" name="descripcion" className="grid-item-select w-100"
                  onChange={(event) => handleOnChangeCombo(event, setFieldValue)}>
                  <MenuItem value={CMB_MENOS_UNO}>Seleccionar</MenuItem>
                  {props.lista.map((valor, index) => (
                    <MenuItem key={valor} value={valor}>{valor}</MenuItem>
                  ))}
                </CustomSelect>
              </Box>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                <span className='text-preguntas'>¿Cu&aacute;ndo?</span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={8}>
                    <CustomSelect id="mes" name="mes" className="grid-item-select w-100"
                      onChange={(event) => handleOnChangeCombo(event, setFieldValue)}>
                      <MenuItem value={CMB_MENOS_UNO}>Mes</MenuItem>
                      {meses.map((mes) => (
                        <MenuItem key={mes.id} value={mes.id}>{mes.nombre}</MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomSelect id="anio" name="anio" className="grid-item-select w-100"
                      onChange={(event) => handleOnChangeCombo(event, setFieldValue)}>
                      <MenuItem value={CMB_MENOS_UNO}>A&ntilde;o</MenuItem>
                      {anios.map((anio) => (
                        <MenuItem key={anio} value={anio}>{anio}</MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                {
                  props.asegurado.titular && props.tipoFlujo !== 3 ? 
                  <span className='text-preguntas'>Y, ¿ahora c&oacute;mo est&aacute;s?</span>
                  :
                  <span className='text-preguntas'>Y, ¿ahora c&oacute;mo est&aacute;?</span>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                <CustomRadioGroup row name="saludActual"
                  onChange={(event) =>
                    handleOnChangeValue(event, setFieldValue)
                  }>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    { props.asegurado.titular && props.tipoFlujo !== 3 ? 
                      <CustomRadio name="saludActual" type="radio" value="Recuperado" label="Ya estoy al 100 :)"/>
                      :
                      <CustomRadio name="saludActual" type="radio" value="Recuperado" label="Ya está al 100 :)"/>
                    }
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    {
                      props.asegurado.titular && props.tipoFlujo !== 3 ? 
                      <CustomRadio name="saludActual" type="radio" value="En tratamiento" label="Sigo en tratamiento :("/>
                      :
                      <CustomRadio name="saludActual" type="radio" value="En tratamiento" label="Sigue en tratamiento :("/>
                    }
                  </Grid>
                </CustomRadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            { (props.add === 4 || props.padecimiento) ? 
              <Button variant="text" onClick={submitForm}>
                GUARDAR
              </Button>
              :
              <Button variant="text" startIcon={totalSaved > 0 ? <AddCircleOutlineIcon /> : <SaveOutlinedIcon />} onClick={submitForm}>
                {totalSaved > 0 ? 'agregar padecimiento' : 'guardar padecimiento'}
                
              </Button>
            }
          </Grid>
        </>
      )}
    </Formik>
  );
}