
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Rodolfo3 from '../../assets/landing/Rodolfo3.png'

export default function Wrapper(props) {
  const [nombre, setNombre] = useState('');

  useEffect(() => {
    setNombre(sessionStorage.getItem('nombre'));
    sessionStorage.clear();
    //TODO: poner un intervalo para que redireccione en automatico a inicio
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[]);

  return (
    <div className="container-wrapper">
      <Container maxWidth="xl">
        <Box className='mX-30'>
          <Grid container direction="row" justifyContent="center" alignItems="center"
            sx={{ maxHeight: '590px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
              <div className="imagen-flujo">
                <img src={Rodolfo3} alt='' />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >              
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
                  <p className="hola-txt">&iexcl;Hola {nombre}!</p><br/>
                  <p className="desc-txt"><b>Te llamaran m&aacute;s tarde</b></p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
    );
}