import * as React from "react"
import Container from "@mui/material/Container"
import "./Contratacion.css"
import Grid from "@mui/material/Grid"
import PasoUno from "./PasoUno"
import { Button, Typography } from "@mui/material"
import PasoDos from "./PasoDos"
import PasoTres from "./PasoTres"
import PasoFirmatres from "./PasoFirmaTres"
import { useNavigate } from "react-router-dom"
import borderLineaUno from "../../assets/img/cuestionario/border-rounded-linea1.svg"
import {
  pasoCTFlujo1,
  pasoFirmaFlujo1,
  pasoCTSAF2,
  pasoFirmaSAF2,
  pasoCTCAF2,
  pasoAseguradosF2,
  pasoFirmaCAF2,
  pasoContratanteSAF3,
  pasoTitularSAF3,
  pasoFirmaSAF3,
  pasoContratanteCAF3,
  pasoTitularCAF3,
  pasoAseguradosF3,
  pasoFirmaCAF3,
  pasoDocsFlujo1,
  pasoDocsFlujo2,
  pasoDocsAseguradosFlujo2,
  pasoDocsFlujo3,
  pasoDocsAseguradosFlujo3,
} from "../Utils/Constantes"
import Stepper from '../Commons/Stepper';
import CargaDocumentos from "./CargaDocumentos"
import CancelarContratacionModal from "./CancelarContratacionModal"
import BlueTooltip from "../Utils/BlueTooltip"
import EventEmitter from "../services/EventEmitter"
export const Context = React.createContext()
export default function Contratacion(props) {
  const dataContratante = JSON.parse(sessionStorage.getItem("interContratante"))
  const numeroAsegurados = dataContratante.asegurados.length - 1
  const navigate = useNavigate()
  const [botonContinuar, setBotonContinuarDisabled] = React.useState(true)
  const [showCancel, setShowCancel] = React.useState(false)
  const [stepper, setStepper] = React.useState(pasoCTFlujo1)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const formRef = React.useRef()
  const handleContinuar = () => {
    switch (dataContratante.tipoFlujo) {
      case 1:
        switch (stepper.paso + 1) {
          /*case 2:
            setStepper(pasoDocsFlujo1)
            break*/
          case 2:
            setStepper(pasoFirmaFlujo1)
            break
         /* case 5:
            setStepper(pasoDocsFlujo1)
            break*/
          case 6:
            navigate("/terminamos-contratacion")
            break
          default:
            setStepper(pasoCTFlujo1)
            break
        }
        break
      case 2:
        if (numeroAsegurados > 0) {
          switch (stepper.paso + 1) {
            case 2:
              setStepper(pasoAseguradosF2)
              break
          /*  case 4:
              setStepper(pasoDocsAseguradosFlujo2)
              break*/
            case 4:
              setStepper(pasoFirmaCAF2)
              break
           /* case 5:
              setStepper(pasoFirmaCAF2)
              break*/
            case 6:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoCTCAF2)
              break
          }
        } else {
          switch (stepper.paso + 1) {
           /* case 2:
              setStepper(pasoDocsFlujo2)
              break*/
            case 2:
              setStepper(pasoFirmaSAF2)
              break
            /*case 5:
              setStepper(pasoFirmaSAF2)
              break*/
            case 6:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoCTSAF2)
              break
          }
        }
        break
      case 3:
        if (numeroAsegurados > 0) {
          switch (stepper.paso + 1) {
            case 2:
              setStepper(pasoTitularCAF3)
              break
            case 3:
              setStepper(pasoAseguradosF3)
              break
          /*  case 4:
              setStepper(pasoDocsAseguradosFlujo3)
              break*/
            case 4:
              setStepper(pasoFirmaCAF3)
              break
           /* case 5:
              setStepper(pasoFirmaCAF3)
              break*/
            case 6:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoContratanteCAF3)
              break
          }
        } else {
          switch (stepper.paso + 1) {
            case 2:
              setStepper(pasoTitularSAF3)
              break
            case 3:
            case 4:
              setStepper(pasoFirmaSAF3)
              break
            /*case 3:
            case 4:
              setStepper(pasoDocsFlujo3)
              break*/
          /*  case 5:
              setStepper(pasoFirmaSAF3)
              break*/
            case 6:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoContratanteSAF3)
              break
          }
        }
        break
      default:
        break
    }
  }

  const habilitaBotonContinuar = () => {
    setBotonContinuarDisabled(false)
  }

  const handleContinue = () => {
    
    if (dataContratante.tipoFlujo !== 1 && stepper.paso === 3) {

      window.dataLayer.push({
        event: "medico_seguro_datos_contratante",
        boton_documentos: "Continuar",
        cotizacion: dataContratante.folio
      })

      handleContinuar()
      
    } else if ((stepper.paso === 1 || stepper.paso === 2) && formRef.current) {
      dataContratante.asegurados.forEach(asegurado => {
        window.dataLayer.push({
          event: "medico_seguro_datos_contratante",
          cotizacion: dataContratante.folio,
          fecha_nacimiento: asegurado.fechanacimiento,
          pais_nacimiento: asegurado.pais,
          nacionalidad: asegurado.nacionalidad,
          sexo: asegurado.genero,
          ocupacion: asegurado.ocupacion,
          material_equipo_riesgo: asegurado.maquinariaPesada ? "Sí" : "No",
          servidor_publico: asegurado.puestoServPublico ? "Sí" : "No",
          boton_datos_contratante: "Continuar",
          estatus_formulario: "success"
        })
      })
      formRef.current.handleSubmit()
    } else if (stepper.paso === 4) {
      setStepper(pasoFirmaSAF2)
    } else if (stepper.paso === 5) {
      window.dataLayer.push({
        event: "medico_seguro_firma",
        boton_firma: "Enviar solicitud",
        cotizacion: dataContratante.folio
      })
      EventEmitter.emit("signature-event")
    }
  }

  const habilitaBoton = () => {
    navigate("/terminamos-contratacion")
  }

  React.useEffect(() => {
    switch (dataContratante.tipoFlujo) {
      case 1:
        switch (dataContratante.ultimoPaso) {
          case 8:
            setStepper(pasoCTFlujo1)
            break
          case 9:
          case 12:
            setStepper(pasoFirmaFlujo1)
            /*setStepper(pasoDocsFlujo1)*/
            break
         /* case 13:
          case 14:
            setStepper(pasoFirmaFlujo1)
            break*/
          case 15:
            navigate("/terminamos-contratacion")
            break
          default:
            setStepper(pasoCTFlujo1)
            break
        }
        break
      case 2:
        if (numeroAsegurados > 0) {
          switch (dataContratante.ultimoPaso) {
            case 8:
              setStepper(pasoCTCAF2)
              break
            case 9:
              setStepper(pasoAseguradosF2)
              break
            case 10:
            case 12:
              setStepper(pasoFirmaCAF2)
             /* setStepper(pasoDocsAseguradosFlujo2)*/
              break
           /* case 13:
            case 14:
              setStepper(pasoFirmaCAF2)
              break*/
            case 15:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoCTCAF2)
              break
          }
        } else {
          switch (dataContratante.ultimoPaso) {
            case 8:
              setStepper(pasoCTSAF2)
              break
            case 9:
            case 12:
              setStepper(pasoFirmaSAF2)
              // setStepper(pasoDocsFlujo2)
              break
            /*case 13:
            case 14:
              setStepper(pasoFirmaSAF2)
              break*/
            case 15:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoCTSAF2)
              break
          }
        }
        break
      case 3:
        if (numeroAsegurados > 0) {
          switch (dataContratante.ultimoPaso) {
            case 8:
              setStepper(pasoContratanteCAF3)
              break
            case 9:
              setStepper(pasoTitularCAF3)
              break
            case 10:
              setStepper(pasoAseguradosF3)
              break
            case 11:
            case 12:
              setStepper(pasoFirmaCAF3)
             /* setStepper(pasoDocsAseguradosFlujo3)*/
              break
            /*case 13:
            case 14:
              setStepper(pasoFirmaCAF3)
              break*/
            case 15:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoContratanteCAF3)
              break
          }
        } else {
          switch (dataContratante.ultimoPaso) {
            case 8:
              setStepper(pasoContratanteSAF3)
              break
            case 9:
              setStepper(pasoTitularSAF3)
              break
            case 10:
            case 12:
              setStepper(pasoFirmaSAF3)
            /*  setStepper(pasoDocsFlujo3)*/
              break
           /* case 13:
            case 14:
              setStepper(pasoFirmaSAF3)
              break*/
            case 15:
              navigate("/terminamos-contratacion")
              break
            default:
              setStepper(pasoContratanteSAF3)
              break
          }
        }
        break
      default:
        break
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [dataContratante.tipoFlujo, numeroAsegurados])

  const handleCancel = () => {
    if (stepper.paso === 3) {
      window.dataLayer.push({
        event: "medico_seguro_datos_contratante",
        boton_documentos: "Continuar",
        cotizacion: dataContratante.folio
      })
    }

    dataContratante.asegurados.forEach(asegurado => {
      window.dataLayer.push({
        event: "medico_seguro_datos_contratante",
        cotizacion: dataContratante.folio,
        fecha_nacimiento: asegurado.fechanacimiento,
        pais_nacimiento: asegurado.pais,
        nacionalidad: asegurado.nacionalidad,
        sexo: asegurado.genero,
        ocupacion: asegurado.ocupacion,
        material_equipo_riesgo: asegurado.maquinariaPesada ? "Sí" : "No",
        servidor_publico: asegurado.puestoServPublico ? "Sí" : "No",
        boton_datos_contratante: "Completar más tarde",
        estatus_formulario: "success"
      })
    })

    setShowCancel(!showCancel)
  }
  return (
    <>
      <div className="container-wrapper">
        <Container maxWidth="xl" style={{ height: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="mX-25"
          >
            <Grid item xs={12} md={7} pr={1}>
              <div className="linea-amarilla">
                <img src={borderLineaUno} alt="" />
                <div className="text-contratacio">Contrataci&oacute;n</div>
                <div className="text-contratacion-sub ">
                  Por &uacute;ltimo ay&uacute;danos con los datos de
                  contrataci&oacute;n para generar la p&oacute;liza de tu seguro
                  m&eacute;dico.<b> Recuerda que es 100% deducible.</b>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                N&uacute;mero de cotizaci&oacute;n
                <BlueTooltip title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos." />
              </Typography>
              <Typography
                sx={{
                  background: "white",
                  p: 1,
                  pl: 2,
                  borderRadius: 1,
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
                }}
              >
                {dataContratante?.folio || "0000000000"}
              </Typography>
            </Grid>
          </Grid>
          <div className="mX-25 frame-contratacion">
            <div className="frame-contratacion-detalle">
              <Stepper stepper={stepper} />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className="padding-preguntas"
              >
                <Context.Provider value={{ isSubmitting, setIsSubmitting }}>
                  {stepper.paso === 1 && (
                    <PasoUno
                      handleContinuar={handleContinuar}
                      formRef={formRef}
                    />
                  )}
                  {stepper.paso === 2 && (
                    <PasoDos
                      handleContinuar={handleContinuar}
                      formRef={formRef}
                    />
                  )}
                  {stepper.paso === 3 && (
                    <PasoTres
                      handleContinuar={handleContinuar}
                      botonHabilitado={habilitaBotonContinuar}
                    />
                  )}
                  {/*{stepper.paso === 4 && (
                    <CargaDocumentos handleContinuar={handleContinuar} />
                  )}*/}
                  {stepper.paso === 5 && (
                    <PasoFirmatres habilitaBoton={habilitaBoton} />
                  )}
                </Context.Provider>
              </Grid>
            </div>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {stepper.paso !== 5 && (
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Button
                  variant="outlined"
                  disableElevation
                  className="background-w btn-text-mas-tarde boton-cuestionario-sm"
                  onClick={handleCancel}
                  sx={{ width: "100%", p: "10px!important" }}
                >
                  Completar m&aacute;s tarde
                </Button>
              </Grid>
            )}
            <Grid item xs={stepper.paso === 5 ? 12 : 6} sm={6} md={6} lg={6} xl={6}>
              <Button
                variant="contained"
                disableElevation
                disabled={isSubmitting}
                onClick={handleContinue}
                className="boton-cuestionario-sm"
                sx={{ width: "100%", p: "10px!important" }}
              >
                {stepper.paso === 5 ? "Enviar solicitud" : "Continuar"}
              </Button>
            </Grid>
          </Grid>
          <br></br>
        </Container>
        <CancelarContratacionModal
          isVisible={showCancel}
          handleClose={handleCancel}
        />
      </div>
    </>
  )
}
