import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CustomTextField from '../CustomFields/CustomTextField';
import { validationEmbarazoForm, validationForm } from "./ValidationForm"
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import CustomDatePickerLab from '../CustomFields/CustomDatePickerLab';
import CustomErrorMessage from '../CustomFields/CustomErrorMessage';
import { CP_INVALIDO, EDAD_MUJER_MAX, EDAD_MUJER_MIN, FECHA_VALIDA, INTER_CONTRATANTE, MAX_LENGTH_CP, MAX_LENGTH_NAME, TIPO_FLUJO, VALOR_NECESARIO } from '../Utils/Constantes';
import { calcularEdad, generoValueToService, maxLength, serviceToGeneroValue, validaResponse } from '../Utils/Utils';
import Api from '../Api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import BlueTooltip from '../Utils/BlueTooltip';
import Loading from '../Commons/Loader';
import { useScoreLead } from '../../hooks/useScoreLead';

export default function SoloAM(props) {
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [datos, setDatos] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [paso, setPaso] = useState(0);
  const [pasoGenero, setPasoGenero] = useState(false);
  const [pasoCP, setPasoCP] = useState(false);
  const [pasoEmbarazo, setPasoEmbarazo] = useState((props.asegurado && serviceToGeneroValue(props.asegurado.agregado.genero) === 1 ?
    (calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) > EDAD_MUJER_MIN && calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) < EDAD_MUJER_MAX ? true : false) : false));
  const [isMuestraPregunta, setMuestraPregunta] = useState((props.asegurado && serviceToGeneroValue(props.asegurado.agregado.genero) === 1 ?
    (calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) > EDAD_MUJER_MIN && calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) < EDAD_MUJER_MAX ? true : false) : false));
  const [cotizar, setCotizar] = useState(false);
  const [titulo, setTitulo] = useState('Primero, dinos quién es el afortunad@');
  const {device, tipoSeguro, scoreLead} = useScoreLead()
  const handleSiguientePaso = (paso, values, setFieldError) => {
    if (values.nombre.length > 20) {
      console.log(values.nombre.length);
      setFieldError("nombre", MAX_LENGTH_NAME);
    } else {
      if (values.nombre !== "") {
        setTitulo('Nos gustaría saber un poco  más de ' + values.nombre);
  
          window.dataLayer.push ({
              'event': 'form_step_1_submitplus',
              'nombre': 'nombre',
          });
  
        setPaso(paso);
      } else {
        console.log(values.nombre.length);
        setFieldError("nombre", VALOR_NECESARIO);
      }
    }
  }

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    setFieldValue("genero", null);
    setPasoGenero(false);
    setMuestraPregunta(false);
    setFieldValue('embarazo', null);
    setPasoEmbarazo(false);
    setFieldValue('cp', '');
    setPasoCP(false);
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha);
      setPasoGenero(true);
      //validacion pregunta embarazo
      if (calcularEdad(fecha) > EDAD_MUJER_MIN && calcularEdad(fecha) < EDAD_MUJER_MAX) {
        setMuestraPregunta(true);
      }
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA);
    }
  };

  const handleGenero = (genero, setFieldValue) => {
    setFieldValue('embarazo', null);
    setFieldValue('cp', '');
    setPasoEmbarazo(false);
    setPasoCP(false);
    setFieldValue("genero", genero);
    if (genero === 0) {
      setPasoCP(true);
      setFieldValue('cp', '');
      setPasoEmbarazo(false);
    } else {
      if (isMuestraPregunta) {
        setPasoEmbarazo(true);
      } else {
        setPasoCP(true);
      }
    }
    setCotizar(false);
  };

  const handleEmbarazo = (embarazo, setFieldValue) => {
    setFieldValue("embarazo", embarazo);
    setPasoCP(true);
  };

  const handleChangeCP = (event, setFieldValue, setFieldError) => {
    const cp = maxLength(event.target.value, MAX_LENGTH_CP);
    setFieldValue("cp", cp);
    if (cp.length === MAX_LENGTH_CP) {
      Api.validaCP(cp)
        .then(res => {
          if (validaResponse(res) && res.data.existe) {
            setCotizar(true);
          } else {
            setFieldError('cp', CP_INVALIDO);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setCotizar(false);
    }
  };

  const handleContinuar = (values, setSubmitting) => {
    let contratante = {}, aseguradoTitular = {};
    const tipoFlujo = Number(window.sessionStorage.getItem(TIPO_FLUJO) ? window.sessionStorage.getItem(TIPO_FLUJO): props.tipoFlujo);
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      contratante = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      contratante.ultimoPaso = 1;
      contratante.tipoFlujo = tipoFlujo;
      contratante.sexo = generoValueToService(values.genero);
      contratante.cp = values.cp; 
      contratante.statusSegurify = 1;
      contratante.genero = generoValueToService(values.genero);
      contratante.fechanacimiento = values.fechanacimiento;
      aseguradoTitular.nombre = values.nombre;
      aseguradoTitular.fechanacimiento = values.fechanacimiento;
      aseguradoTitular.genero = generoValueToService(values.genero);
      aseguradoTitular.embarazo =  values.embarazo;
      aseguradoTitular.cp = values.cp;
      aseguradoTitular.titular = true;
      aseguradoTitular.indice = 0;
      aseguradoTitular.id = values.id;
			//aseguradoTitular.parentesco = "Titular";
      contratante.asegurados = [].concat(aseguradoTitular);
      window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify({...contratante, dispositivo:device, tipoSeguro, scoreLead}));      
      if(calcularEdad(new Date(values.fechanacimiento)) > 70) {
        props.handleAdvertencias(1, true);
      } else 
      if(values.genero === 1 && values.embarazo) {
        props.handleAdvertencias(2, true);
      } else {
        Api.actualizaContratante(contratante.id, {...contratante,  dispositivo: device, tipoSeguro, scoreLead})
        .then(res => {
          Api.getContratante(contratante.folio)
          .then(res => {
            setOpenLoader(false);
            window.sessionStorage.setItem(INTER_CONTRATANTE,JSON.stringify({...res.data.contratante, dispositivo: device, tipoSeguro, scoreLead}));
            setSubmitting(false);
            //am
            navigate('/datos/alguien-mas/agregar-mas');
          })
          .catch(error => {
            setOpenLoader(false);
						setSubmitting(false);
            console.log(error);
          });
        })
        .catch(error => {
          setOpenLoader(false);
          setSubmitting(false);
          console.log(error);
        });
      }
    }
  };

  const handleRegresar = (value) => {
    switch (paso) {
      case 0:
        navigate('/bienvenida');
        break;
      case 1:
        setPasoGenero(false);
        setMuestraPregunta(false);
        setPasoEmbarazo(false);
        setPasoCP(false);
        setCotizar(false);
        setPaso(0);
        break;
      default:
        props.handleRegresar();
        break;
    }

  }

  useEffect(() => {

        window.dataLayer.push ({
          'event': 'form_step1__viewplus'
        });

    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      if(contratante.asegurados && contratante.asegurados.length > 0){
        const titular = contratante.asegurados.find(asegurado => asegurado.titular === true);
        if (titular && titular.fechanacimiento) {
          setDatos(titular);
          setTitulo('Nos gustaría saber un poco  más de ' + titular.nombre);
          setPaso(1);
          setCotizar(true);
          setPasoGenero(true);
          if(serviceToGeneroValue(titular.genero) === 1){
            if(calcularEdad(new Date(titular.fechanacimiento)) > EDAD_MUJER_MIN && calcularEdad(new Date(titular.fechanacimiento)) < EDAD_MUJER_MAX){
              setMuestraPregunta(true);
              setPasoEmbarazo(true);
            }
          }
          setPasoCP(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);

  return (
    <>
      { isLoading ? <CircularProgress />  :
        <>
          { (!props.isMobile) ?
            <IconButton className="back-button" onClick={handleRegresar}>
              <ArrowBackIcon/>
            </IconButton>
            :
            <IconButton className="hidden-movil" onClick={handleRegresar} ref={props.regresarRef}>
              <ArrowBackIcon/>
            </IconButton>
          }
          <Box className='box-perfilamiento-form'>
            <Formik validateOnChange={true}
              initialValues={{
                nombre: (datos && datos.nombre ? datos.nombre : ''),
                fechanacimiento: (datos && datos.fechanacimiento ? new Date(datos.fechanacimiento) : new Date()),
                genero: (datos && datos.genero ? serviceToGeneroValue(datos.genero) : ''),
                embarazo: (datos && datos.embarazo !== null ? datos.embarazo : null),
                cp: (datos && datos.cp ? datos.cp : ''),
                id: (datos && datos.id ? datos.id : null),
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
								setOpenLoader(true);
                handleContinuar(values, setSubmitting);
              }}
              validationSchema={pasoEmbarazo ? validationEmbarazoForm : validationForm}>
              {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
                <Form>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                      <p className="datos-txt">{titulo}</p>
                    </Grid>
                    {paso === 0 &&
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                          <div className="label-asegurado mX-10">¿C&oacute;mo se llama?</div>
                          <CustomTextField id="nombre" name="nombre" type="text" placeholder="Nombre" className="grid-item-input" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                          <Button 
                            variant="contained" 
                            disableElevation 
                            endIcon={<ArrowForwardIcon />} 
                            className="button-continuar w-100"
                            onClick={() => handleSiguientePaso(1, values, setFieldError)}>
                            Continuar
                          </Button>
                        </Grid>
                      </>
                    }
                    {paso === 1 &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                        <p>¿Cu&aacute;ndo naci&oacute;?</p>
                        <CustomDatePickerLab id="fechanacimiento" name="fechanacimiento" type="text" placeholder="Fecha Nacimiento"
                          className="grid-item-input w-100"
                          disableFuture onChange={(newValue) =>
                            handleChangeFechaNacimiento(newValue, setFieldValue, setFieldError)}
                        />
                      </Grid>
                    }
                    {pasoGenero &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
											<p className="p-genero-tooltip">¿C&uacute;al es tu sexo de nacimiento? 
												<BlueTooltip
													title="Sabemos que esta pregunta puede ser complicada, pero para las aseguradora es un dato muy importante para saber cómo protegerte.">
												</BlueTooltip>
											</p>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant="outlined" disableElevation
                              className={clsx({ "boton-form-seleccionado": (values.genero === 0) }, { "boton-form-datos": true })}
                              onClick={(event) => handleGenero(0, setFieldValue)}>
                              Hombre
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="div-boton-derecho">
                            <Button variant="outlined" disableElevation
                              className={clsx({ "boton-form-seleccionado": (values.genero === 1) }, { "boton-form-datos": true })}
                              onClick={(event) => handleGenero(1, setFieldValue)}>
                              Mujer
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                    {pasoEmbarazo &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                        <p className="mX-10">¿Est&aacute; embarazada?</p>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="div-boton-derecho">
                            <Button variant="outlined" disableElevation
                              className={clsx({ "boton-form-seleccionado": (values.embarazo) }, { "boton-form-datos": true })}
                              onClick={(event) => handleEmbarazo(true, setFieldValue)}>
                              Si
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button variant="outlined" disableElevation
                              className={clsx({ "boton-form-seleccionado": (values.embarazo === false) }, { "boton-form-datos": true })}
                              onClick={(event) => handleEmbarazo(false, setFieldValue)}>
                              No
                            </Button>
                          </Grid>
                          <CustomErrorMessage name="embarazo" />
                        </Grid>
                      </Grid>
                    }
                    {pasoCP &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                        <p className="fecha-nacimiento">¿C&uacute;al es su c&oacute;digo postal?</p>
                        <CustomTextField id="cp" name="cp" type="text" placeholder="C.P." className="grid-item-input"
                          onChange={(event) => handleChangeCP(event, setFieldValue, setFieldError)} />
                      </Grid>
                    }
                    {cotizar &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
                        <Button variant="outlined" disableElevation
                          className="boton-submit boton-form-seleccionado" onClick={submitForm} disabled={isSubmitting}>
                          COTIZAR
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
          <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
        </>
      }
    </>
  );
}