import * as React from 'react';
import './Cuestionario.css';
import borderLineaDos from '../../assets/img/cuestionario/border-rounded-linea2.svg';
import borderLineaComplete from '../../assets/img/cuestionario/border-rounded-complete.svg';
import { Button, Grid, MenuItem } from '@mui/material';
import { medidaGridByLength, validaCombo, validaResponse } from '../Utils/Utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApiCuestionario from '../Api/Cuestionario';
import Api from '../Api/Api';
import { Form, Formik } from "formik";
import { validationPasoUno } from "./ValidationForm.jsx"
import CustomRadioGroup from "../CustomFields/CustomRadioGroup";
import CustomRadio from "../CustomFields/CustomRadio";
import CustomSelect from "../CustomFields/CustomSelect";
import { CMB_MENOS_UNO, INTER_CONTRATANTE, TIPO_FLUJO } from '../Utils/Constantes';
import { pasoUno } from '../Utils/Cuestionario';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../Commons/Loader';

export default function PasoUno(props) {
  const [tipoFlujo, setTipoFlujo] = useState(0);
  const [openLoader, setOpenLoader] = React.useState(false);

  const handleOnChangeValue = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleOnChangeCombo = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleGuardar = (values, setSubmitting) => {
    window.dataLayer.push({
      'event': 'form_step_5_submit'
    });
    let estilosVida = [];
    const IdAsegurado = props.asegurado.id;
    let idContratante = null;
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
      idContratante = contratante.id
    }
    if (values.deporte === "1") {
      props.asegurado.deporte = values.descrDeporteD
      props.asegurado.descrDeporte = values.descrDeporteD
      Api.actualizaAsegurado(idContratante, props.asegurado)
        .then(res => {
          setOpenLoader(false);
          if (validaResponse(res)) {
          } else {
            setSubmitting(false);
            console.log("ERROR");
          }
        })
        .catch(error => {
          setOpenLoader(false);
          console.log(error);
          setSubmitting(false);
        });
    }
    if (values.fumar === "1") {
      let fumar = {
        nombreEstilo: "Fumar",
        cantidad: values.cantidadF,
        frecuencia: values.frecuenciaF,
        dtDesdeCuando: values.dtDesdeCuandoF,
        id: values.idF,
      }
      estilosVida = estilosVida.concat(fumar);
    }
    if (values.beber === "1") {
      let beber = {
        nombreEstilo: "Beber",
        cantidad: values.cantidadB,
        frecuencia: values.frecuenciaB,
        dtDesdeCuando: values.dtDesdeCuandoB,
        id: values.idB,
      }
      estilosVida = estilosVida.concat(beber);
    }
    if (values.drogarse === "1") {
      let drogarse = {
        nombreEstilo: "Drogarse",
        cantidad: values.cantidadD,
        frecuencia: values.frecuenciaD,
        dtDesdeCuando: values.dtDesdeCuandoD,
        id: values.idD,
      }
      estilosVida = estilosVida.concat(drogarse);
    }
    if (estilosVida.length > 0) {
      setOpenLoader(true);
      ApiCuestionario.guardaEstilosVida(estilosVida, idContratante, IdAsegurado)
        .then(res => {
          setSubmitting(false);
          setOpenLoader(false);
          if (validaResponse(res)) {
            if (res.data.estilovida && res.data.estilovida.length > 0) {
              props.asegurado.estilosVida = res.data.estilovida;
              props.actualizaContratante(props.asegurado);
            }
            props.siguienteAsegurado(props.asegurado.indice);
          } else {
            console.log("ERROR");
          }
        })
        .catch(error => {
          setOpenLoader(false);
          console.log(error);
          setSubmitting(false);
        });
    } else {
      setOpenLoader(false);
      setSubmitting(false);
      props.siguienteAsegurado(props.asegurado.indice);
    }
  };

  const deporteRegistrado = () => {
    if (props.asegurado && props.asegurado.deporte && props.asegurado.descrDeporte) {
      return true;
    } else {
      return false;
    }
  };

  const existeVidaRegistrado = (estilo) => {
    return props.asegurado && props.asegurado.estilosVida && props.asegurado.estilosVida.length > 0
      && props.asegurado.estilosVida.find(estiloVida => estiloVida.nombreEstilo === estilo) !== undefined;
  };

  const getEstilosVidaR = (estilo) => {
    return props.asegurado.estilosVida.find(estiloVida => estiloVida.nombreEstilo === estilo);
  };

  const getCantidadByEstiloVida = (estilo) => {
    return existeVidaRegistrado(estilo) ? getEstilosVidaR(estilo).cantidad : CMB_MENOS_UNO;
  };

  const getFrecuenciaByEstiloVida = (estilo) => {
    return existeVidaRegistrado(estilo) ? getEstilosVidaR(estilo).frecuencia : CMB_MENOS_UNO;
  };

  const getDesdeCuandpByEstiloVida = (estilo) => {
    return existeVidaRegistrado(estilo) ? getEstilosVidaR(estilo).dtDesdeCuando : CMB_MENOS_UNO;
  };

  const getIdByEstiloVida = (estilo) => {
    return existeVidaRegistrado(estilo) ? getEstilosVidaR(estilo).id : null;
  };

  const guardoEV = () => {
    return ((props.asegurado.estilosVida && props.asegurado.estilosVida.length > 0) || (props.asegurado.descrDeporte !== null) || props.registroPaso) ? "0" : null;
  };

  useEffect(() => {
    window.dataLayer.push({
      'event': 'form_step_5_view'
    });
    if (window.sessionStorage.getItem(TIPO_FLUJO)) {
      setTipoFlujo(JSON.parse(sessionStorage.getItem(TIPO_FLUJO)));
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);

  return (
    <>
      <Accordion disabled={!props.disabled} square={true} expanded={props.disabled}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="paso1-content"
          id="paso1-header">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="linea-amarilla">
              {props.indice.indice < props.indice.aseLength ?
                <img src={borderLineaDos} alt='' />
                :
                <img src={borderLineaComplete} alt='' />
              }
              <div className="titular">{props.asegurado.titular ? "Titular" : "Asegurado adicional"}</div>
              <div className="nombre">{props.asegurado.nombre}</div>
              <hr className="divider" />
            </div>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Formik validateOnChange={true} innerRef={props.formRef}
            initialValues={{
              deporte: (deporteRegistrado(props.asegurado) ? "1" : guardoEV()),
              descrDeporteD: (deporteRegistrado(props.asegurado) ? props.asegurado.descrDeporte : CMB_MENOS_UNO),
              fumar: (existeVidaRegistrado("Fumar") ? "1" : guardoEV()),
              cantidadF: (getCantidadByEstiloVida("Fumar")),
              frecuenciaF: (getFrecuenciaByEstiloVida("Fumar")),
              dtDesdeCuandoF: (getDesdeCuandpByEstiloVida("Fumar")),
              idF: (getIdByEstiloVida("Fumar")),
              beber: (existeVidaRegistrado("Beber") ? "1" : guardoEV()),
              cantidadB: (getCantidadByEstiloVida("Beber")),
              frecuenciaB: (getFrecuenciaByEstiloVida("Beber")),
              dtDesdeCuandoB: (getDesdeCuandpByEstiloVida("Beber")),
              idB: (getIdByEstiloVida("Beber")),
              drogarse: (existeVidaRegistrado("Drogarse") ? "1" : guardoEV()),
              cantidadD: (getCantidadByEstiloVida("Drogarse")),
              frecuenciaD: (getFrecuenciaByEstiloVida("Drogarse")),
              dtDesdeCuandoD: (getDesdeCuandpByEstiloVida("Drogarse")),
              idD: (getIdByEstiloVida("Drogarse")),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setOpenLoader(true);
              handleGuardar(values, setSubmitting);
            }}
            validationSchema={validationPasoUno}>
            {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
              <Form>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" >
                  {pasoUno.map((pregunta, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                        className={clsx({ "mt-16": (index > 0) })}>
                        {
                          props.asegurado.titular && tipoFlujo !== 3 ?
                            <span className='text-preguntas'>{pregunta.pregunta}</span>
                            :
                            <span className='text-preguntas'>{pregunta.preguntaAdicional}</span>
                        }
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomRadioGroup row name={pregunta.campo}
                          onChange={(event) =>
                            handleOnChangeValue(event, setFieldValue)
                          }>
                          <Grid container spacing={{ xs: 3, md: 12 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                            {pregunta.respuestas.map((res, jndex) => (
                              <Grid item xs={6} sm={6} md={6} key={jndex}>
                                {
                                  props.asegurado.titular && tipoFlujo !== 3 ?
                                    <CustomRadio name={pregunta.campo} type="radio" value={res.valor}
                                      label={res.respuesta} />
                                    :
                                    <CustomRadio name={pregunta.campo} type="radio" value={res.valor}
                                      label={res.respuestaAdicional} />
                                }
                              </Grid>
                            ))}
                          </Grid>
                        </CustomRadioGroup>
                      </Grid>
                      {(values[pregunta.campo] === "1") &&
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                          className="mt-15">
                          {pregunta.preguntasDetalle.map((det, kndex) => (
                            <React.Fragment key={kndex}>
                              <Grid item xs={12} sm={12}
                                md={medidaGridByLength(pregunta.preguntasDetalle.length)}
                                lg={medidaGridByLength(pregunta.preguntasDetalle.length)}
                                xl={medidaGridByLength(pregunta.preguntasDetalle.length)}>
                                <span className='text-preguntas'>{det.pregunta}</span>
                                {/* Cada combo deberia tener su lista propia */}
                                <CustomSelect id={det.nombre + pregunta.identificador} name={det.nombre + pregunta.identificador} className="grid-item-select w-100"
                                  validate={validaCombo} onChange={(event) => handleOnChangeCombo(event, setFieldValue)} sx={{ mt: 1 }}>
                                  <MenuItem value={CMB_MENOS_UNO}>Seleccionar</MenuItem>
                                  {det.valores.map((valor, index) => (
                                    <MenuItem key={valor} value={valor}>{valor}</MenuItem>
                                  ))}
                                </CustomSelect>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      }
                    </React.Fragment>
                  ))}
                </Grid>
                {props.muestraGuardar &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100 mt-15">
                    <Button variant="outlined" disableElevation
                      className="boton-submit boton-form-seleccionado" onClick={submitForm}
                    > GUARDAR
                    </Button>
                  </Grid>
                }
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>

  );


}