
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import RodolfoOK from '../../assets/img/detallesCotizacion/rodolfo_ok.png'
import { INTER_CONTRATANTE } from '../Utils/Constantes';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';

export default function Gracias(props) {
  const navigate = useNavigate();
  const dataContratante = JSON.parse(sessionStorage.getItem("interContratante"));
  const handleSalir = () => {
    sessionStorage.clear();
    navigate('/inicio');
  };

  useEffect(() => {
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      //setDatos(JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE)));
    }
    /* Especifica c&oacute;mo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);

  return (
    <div className="container-wrapper">
      <Container maxWidth="xl">
        <Box className='mX-30'>
          <Grid container direction="row" justifyContent="center" alignItems="center"
            sx={{ maxHeight: '590px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} >
              <div className="imagen-flujo">
                  <img src={RodolfoOK} alt='' />
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem className="vertical-divider"/>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >              
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
                  <p className="hola-txt">Gracias por crear tu plan ideal</p><br/>
                  <p className="desc-txt">
                    Ya tenemos una mejor idea de que es lo que necesitas, es por eso que <b>un asesor inter te contactar&aacute; para brindarte toda la informaci&oacute;n y el precio final de tu plan personalizado.</b>
                    Tambi&eacute;n te dejamos tu n&uacute;mero de cotizaci&oacute;n por si quieres ingresar a nuestra p&aacute;gina y ver la actualizaci&oacute;n desde la comodidad de tu smartphone o computadora 
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
                  <p className="desc-txt text-align-center">
                    No. de cotización <b> {dataContratante.folio} </b>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
                  <Button variant="contained" disableElevation
                    className="boton-para w-100"
                    onClick={handleSalir}>
                    ¡S&uacute;per, gracias!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}