import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AseguradoAdicional from './AseguradoAdicional';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { calcularEdad } from '../Utils/Utils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useNavigate } from 'react-router-dom';
import { EXCLUSION_E, EXCLUSION_M, INTER_CONTRATANTE } from '../Utils/Constantes';
import Api from '../Api/Api';
import Loading from '../Commons/Loader';
import { Dialog, DialogContent } from '@mui/material';

export default function TarjetaAgregar(props) {
  const navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [contratante, setContratante] = useState(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
  const [agregado, setAgregado] = useState(null);
  const [openFormModal, setOpenFormModal] = useState(false);

  const handleOnClickAgregar = () => {
    setAgregado(null);
    setOpenFormModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenFormModal(false);
    }
    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      setContratante(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
    }
    if(event === 1){
      if(window.sessionStorage.getItem(EXCLUSION_M)){
        props.handleAdvertencias(1,false);
      }
    }
    if(event === 2){
      if(window.sessionStorage.getItem(EXCLUSION_E)){
        props.handleAdvertencias(2,false);
      }
    }
  };

  const handleOnClickEditar = (i, agregado) => {
    setAgregado({indice:i, agregado:agregado});
    setOpenFormModal(true);
  };

  const handleOnClickEliminar = (i, currentAgregado) => {
    if(currentAgregado.id !== undefined){
      Api.eliminarAsegurado(contratante.id, currentAgregado.id)
      .then(res => {
        Api.getContratante(contratante.folio)
        .then(res => {
          window.sessionStorage.setItem(INTER_CONTRATANTE,JSON.stringify(res.data.contratante));
          setContratante(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
        })
        .catch(error => {
          console.log(error);
        });
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      contratante.asegurados.splice(i, 1);
      window.sessionStorage.setItem(INTER_CONTRATANTE,JSON.stringify(contratante));
      setContratante(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
    }

  };
  
  const getTexto = (currentAgregado) => {
    return currentAgregado.nombre;
  }

  const AdicionalText = (props) =>{
    if(calcularEdad(new Date(props.currentAgregado.fechanacimiento)) > 70 || (props.currentAgregado.genero === 1 && props.currentAgregado.embarazo === 0)){
      return (
        <div className="nombre-asegurado advertencia-exclusion"><WarningAmberIcon/>&nbsp;&nbsp;{getTexto(props.currentAgregado)}</div>
      );
    } else {
      return (
        <div className="nombre-asegurado">{getTexto(props.currentAgregado)}</div>
      );
    }
  };

  const handleContinuar = () =>{
    setOpenLoader(true);

        window.dataLayer.push ({
          'event': 'form_step_2_submitADI'
        });
    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      const contratante = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      contratante.ultimoPaso = 2;
			Api.actualizaContratante(contratante.id, contratante)
			.then(res => {
        Api.getContratante(contratante.folio)
        .then(res => {
          setOpenLoader(false);
          window.sessionStorage.setItem(INTER_CONTRATANTE,JSON.stringify(res.data.contratante));
          navigate('/cotizacion');
        })
        .catch(error => {
          setOpenLoader(false);
          console.log(error);
        });
			})
			.catch(error => {
        setOpenLoader(false);
				console.log(error);
			});

    }
  };

  useEffect(() => {
    window.dataLayer.push ({
      'event': 'form_step_2_viewADI'
    });

    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      setContratante(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[]);

  return(
    <>
      <Dialog open={openFormModal} onClose={(event, reason) => handleClose(event, reason)} disableEscapeKeyDown={true}
        maxWidth="sm" fullWidth={true}>
        <DialogContent>
          <AseguradoAdicional handleClose={handleClose} asegurado={agregado}/>
        </DialogContent>
      </Dialog>
      <Grid container direction="row" justifyContent="center" spacing={1}>
        {contratante.asegurados.length < 6 && 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <div className="agregar mX-5">
              <Button disableElevation startIcon={<AddCircleOutlineIcon />} className="button-continuar w-100"
                onClick={handleOnClickAgregar}>
                Agregar
              </Button>
            </div>
          </Grid>
        }
        {contratante.asegurados.map((currentAgregado, i) => (
          <React.Fragment key={i}>
            {(!currentAgregado.titular) &&
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="border-x">
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
                    <AdicionalText currentAgregado={currentAgregado}/>
                  </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >&nbsp;</Grid>
                    <Grid container item xs={2} sm={2} md={2} lg={2} xl={2} justifyContent="flex-end" >
                      <IconButton  className="item-tarjeta" onClick={() => handleOnClickEditar(i, currentAgregado)}>
                        <EditIcon/>
                      </IconButton>
                    </Grid>
                    <Grid container item xs={2} sm={2} md={2} lg={2} xl={2} justifyContent="flex-end" >
                      <IconButton  className="item-tarjeta" onClick={() => handleOnClickEliminar(i, currentAgregado)}>
                        <HighlightOffIcon/>
                      </IconButton>
                    </Grid>
                </Grid>
              </Grid>
            }
          </React.Fragment>
        ))}
        {(contratante.asegurados.length > 1) && 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100">
            <div className="agregar mX-5">
              <Button variant="contained" disableElevation endIcon={<ArrowForwardIcon />} className="button-continuar w-100"
                onClick={handleContinuar}>
                Cotizar
              </Button>
            </div>
          </Grid>
        }
      </Grid>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>
  );
}