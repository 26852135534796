import * as React from 'react';
import { useEffect, useState } from 'react';
import './Cuestionario.css';
import borderLineaDos from '../../assets/img/cuestionario/border-rounded-linea2.svg';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomRadioGroup from '../CustomFields/CustomRadioGroup';
import CustomRadio from '../CustomFields/CustomRadio';
import ApiCuestionario from '../Api/Cuestionario'
import { Form, Formik } from "formik";
import { validationPasoTres } from "./ValidationForm.jsx"
import clsx from 'clsx';
import { pasoTres } from '../Utils/Cuestionario';
import carita0 from '../../assets/img/cuestionario/carita0.png';
import carita1 from '../../assets/img/cuestionario/carita1.png';
import { INTER_CONTRATANTE, TIPO_FLUJO } from '../Utils/Constantes';
import Congenito from './Padecimientos/Congenito';
import Hospitalizacion from './Padecimientos/Hospitalizacion';
import { validaResponse } from '../Utils/Utils';
import Loading from '../Commons/Loader';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function PasoTres(props) {
  const [contratante, setContratante] = useState({});
  const [asegurado, setAsegurado] = useState({});
  const [congenitos, setCongenitos] = useState([]);
  const [hospitalizaciones, setHospitalizaciones] = useState([]);
  const[tipoFlujo, setTipoFlujo] = useState(0);
  const [openLoader, setOpenLoader] = useState(false);

  const handleOnChangeValue = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    if(name === "congenita"){
      props.setEsCongenito(value === "1")
    }
    setFieldValue(name, value);
  };
  const handleRemovePadecimientoC = (event, padecimiento, setFieldValue) => {
    setFieldValue("congenita","0");
    ApiCuestionario.deleteCongenito(contratante.id, asegurado.id, padecimiento.id)
      .then(res => {
        if(validaResponse(res)){
          const currentIndex = props.asegurado.congenitos.findIndex((cuurent) => cuurent.id === padecimiento.id);
          props.asegurado.congenitos.splice(currentIndex, 1);
          setCongenitos(props.asegurado.congenitos);
          props.actualizaContratante(props.asegurado);
          setFieldValue("congenita","1");
        } else {
          console.log("ERROR");
        }
      })
      .catch(error => {
        console.log(error);
      });
    event.stopPropagation();
  };

  const handleGuardarPadecimientoC = (values) => {
    setOpenLoader(true);
    const item = {
      id: values.id,
      descripcion: values.descripcion,
      mes: values.mes,
      anio: values.anio,
      saludActual: values.saludActual
    };
    if(item.id !== null && item.id !== undefined){
      ApiCuestionario.updateCongenito(item, contratante.id, asegurado.id)
        .then(res => {
          setOpenLoader(false);
          if(validaResponse(res)){//aqui ver como resuelve el objeto de regreso y mejor usar ese padecimiento
            if(res.data.padecimiento !== null) {
              const currentIndex = props.asegurado.congenitos.findIndex((padecimiento) => padecimiento.id === values.id);
              props.asegurado.congenitos.splice(currentIndex, 1, res.data.padecimiento);
              setCongenitos(props.asegurado.congenitos);
              props.actualizaContratante(props.asegurado);
            }
          } else {
            console.log("ERROR");
          }
        })
        .catch(error => {
          setOpenLoader(false);
          console.log(error);
        });
    } else {
      ApiCuestionario.guardaCongenito(item, contratante.id, asegurado.id)
      .then(res => {
        setOpenLoader(false);
        if(validaResponse(res)){
          if(res.data.padecimiento !== null) {
            const congenitosAux = props.asegurado.congenitos.concat(res.data.padecimiento);
            props.asegurado.congenitos = congenitosAux;
            setCongenitos(props.asegurado.congenitos);
            props.actualizaContratante(props.asegurado);
          }
        } else {
          console.log("ERROR");
        }
      })
      .catch(error => {
        setOpenLoader(false);
        console.log(error);
      });
    }
  };

  const handleGuardarPadecimientos = (aseguradoEditado) => {
    props.actualizaContratante(aseguradoEditado);
  };
  
  const handleContinuar = (setSubmitting) => {
        window.dataLayer.push ({
          'event': 'form_step_7_submit'
        });
    setSubmitting(false);
    props.siguienteAsegurado(props.asegurado.indice);
  };

  const validaPadecimiento = (padecimientos, padecimientoEvalua) => {
    const currentIndex = padecimientos.findIndex((curPad) => curPad[padecimientoEvalua] !== undefined);
    return ( currentIndex > -1 ? "1" : "");
  };

  const guardoPadecimientos = () => {
    return ( (props.asegurado.congenitos && props.asegurado.congenitos.length > 0) 
              || (props.asegurado.hospitalizaciones && props.asegurado.hospitalizaciones.length > 0) 
                || props.registroPaso) ? "0" : "";
  };

  useEffect(() => {
        window.dataLayer.push ({
          'event': 'form_step_7_view'
        });
    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      const contra = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      setContratante(contra);
      const aseguradoLocal = contra.asegurados.find(asegurado => asegurado.id === props.asegurado.id);
      setAsegurado(aseguradoLocal);
      setCongenitos(aseguradoLocal.congenitos);
      setHospitalizaciones(aseguradoLocal.hospitalizaciones);
      setTipoFlujo(contra.tipoFlujo);
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[props.asegurado]);

  useEffect(() => {
    if (window.sessionStorage.getItem(TIPO_FLUJO)) {
      if(tipoFlujo === 0){
        setTipoFlujo(JSON.parse(sessionStorage.getItem(TIPO_FLUJO)));
      }
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [tipoFlujo]);

  return (
    <>
      <Accordion disabled={!props.disabled} square={true} expanded={props.disabled}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={props.asegurado.nombre+"-content"}
          id={props.asegurado.nombre+"-header"}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="linea-amarilla">
              <img src={borderLineaDos} alt='' />
              <div className="titular">{ props.asegurado.titular ? "Titular" : "Asegurado adicional" }</div>
              <div className="nombre">{props.asegurado.nombre}</div>
            </div>
            <hr className="divider" />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Formik validateOnChange={true} innerRef={props.formRef}
            initialValues={{
              congenita: (props.asegurado.congenitos.length > 0 ? "1" : guardoPadecimientos()),
              hospitalizaciones: (props.asegurado.hospitalizaciones.length > 0 ? "1" : guardoPadecimientos()),
              cancer: validaPadecimiento(props.asegurado.hospitalizaciones,"cancer"),
              corazon: validaPadecimiento(props.asegurado.hospitalizaciones,"corazon"),
              neurologico: validaPadecimiento(props.asegurado.hospitalizaciones,"neurologico"),
              digestivo: validaPadecimiento(props.asegurado.hospitalizaciones,"digestivo"),
              endocrinologico: validaPadecimiento(props.asegurado.hospitalizaciones,"endocrinologico"),
              pulmonar: validaPadecimiento(props.asegurado.hospitalizaciones,"pulmonar"),
              inmunologico: validaPadecimiento(props.asegurado.hospitalizaciones,"inmunologico"),
              infectologia: validaPadecimiento(props.asegurado.hospitalizaciones,"infectologia"),
              ginecologico: validaPadecimiento(props.asegurado.hospitalizaciones,"ginecologico"),
              muscular: validaPadecimiento(props.asegurado.hospitalizaciones,"muscular"),
              oftalmologico: validaPadecimiento(props.asegurado.hospitalizaciones,"oftalmologico"),
              auditivo: validaPadecimiento(props.asegurado.hospitalizaciones,"auditivo"),
              dermatologico: validaPadecimiento(props.asegurado.hospitalizaciones,"dermatologico"),
              viasUrinarias: validaPadecimiento(props.asegurado.hospitalizaciones,"viasUrinarias"),
              padecimientosO: validaPadecimiento(props.asegurado.hospitalizaciones,"padecimientosO"),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleContinuar( setSubmitting);
            }}
            validationSchema={validationPasoTres}>
            {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
              <Form>
                {pasoTres.map((pregunta, index) => (
                  <React.Fragment key={index}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" 
                        className={clsx({"mt-16": (index > 0)})}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {
                            props.asegurado.titular && tipoFlujo !== 3 ? 
                            <span className='text-preguntas'>{pregunta.pregunta}</span>
                            :
                            <span className='text-preguntas'>{pregunta.preguntaAdicional}</span>
                          }
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomRadioGroup row name={pregunta.campo}
                          onChange={(event) => handleOnChangeValue(event, setFieldValue) }
                          >
                          {pregunta.respuestas.map((res, jndex) => (
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={jndex} className='inline'>
                            {
                              props.asegurado.titular && tipoFlujo !== 3 ? 
                              <CustomRadio name={pregunta.campo} type="radio" value={res.valor} label={res.respuesta}/>
                              :
                              <CustomRadio name={pregunta.campo} type="radio" value={res.valor} label={res.respuestaAdicional}/>
                            }
                              {res.emoticon !== null &&
                                <img src={res.emoticon=== 0 ? carita0 :carita1} alt={res.emoticon} />
                              }
                            </Grid>
                          ))}
                        </CustomRadioGroup>
                      </Grid>
                    </Grid>
                    {(values[pregunta.campo] === "1") &&
                      <>
                        {pregunta.tipoRespuesta === "form" && 
                        <>
                          <Congenito congenitos={congenitos} handleGuardarPadecimiento={handleGuardarPadecimientoC} 
                              handleRemovePadecimiento={handleRemovePadecimientoC} length={props.asegurado.congenitos.length}
                              cantidadCongenitos={props.cantidadCongenitos} setFieldValue={setFieldValue}
                              setAlreadySaveAilling={props.setAlreadySaveAilling}
                              tipoFlujo={contratante.tipoFlujo} asegurado={props.asegurado}/>
                          {
                            props.showWarningMulti && 
                              <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert variant='filled' severity="error">Es necesario guardar su enfermedad congenita!</Alert>
                              </Stack>
                          }
                        </>
                          
                        }
                        {pregunta.tipoRespuesta === "listaPreguntas" && 
                          <Hospitalizacion hospitalizaciones={hospitalizaciones} handleOnChangeValue={handleOnChangeValue} setFieldValue={setFieldValue} values={values}
                          handleGuardarPadecimiento={handleGuardarPadecimientos} asegurado={props.asegurado}/>
                        }
                      </>
                    }
                  </React.Fragment>
                ))}
                {props.muestraGuardar &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100 mt-15">
                    <Button variant="outlined" disableElevation
                      className="boton-submit boton-form-seleccionado" onClick={submitForm}
                      > GUARDAR
                    </Button>
                  </Grid>
                }
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>
  );

}