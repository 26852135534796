import axios from "axios"
import { CLIENT_COT_ID, CLIENT_SECRET } from "../Utils/Constantes"

let clientId = CLIENT_COT_ID
let clientSecret = CLIENT_SECRET

const enviarCotizacion = async (dataObj) => {

    let configForToken = {
        "client_id": clientId,
        "client_secret": clientSecret
    }
    const token = await axios
        .post("https://demos.Inter.mx/v1/" + "authorization/token", configForToken)
        .then(res => {
            let headers = {
                headers: { Authorization: `Bearer ${res.data.data.access_token}` },
            }
            const resData = axios
                .post("https://demos.inter.mx/v1/" + "mail/quote", dataObj, headers)
                .then(res => {
                    return res
                })
                .catch(err => console.log(err))
            return resData
        })
        .catch(err => console.log(err))

}
export default {
    enviarCotizacion
}