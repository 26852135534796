import React, { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './Cuestionario.css';
import borderLineaUno from '../../assets/img/cuestionario/border-rounded-linea1.svg';
import PasoUno from './PasoUno';
import PasoDos from './PasoDos';
import PasoTres from './PasoTres';
import PasoCuatro from './PasoCuatro';
import { INTER_CONTRATANTE, primer, segundo, segundoAlt, tercero, terceroAlt, cuarto, cuartoAlt, CMB_MENOS_UNO } from '../Utils/Constantes';
import { calcularEdad, ordenarAsc } from '../Utils/Utils';
import Stepper from '../Commons/Stepper';
import { useNavigate } from 'react-router-dom';
import Api from '../../components/Api/Api';
import useWindowSize from '../Utils/useWindowSize';
import { Typography } from '@mui/material';
import BlueTooltip from '../Utils/BlueTooltip';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Cuestionario(props) {
  const navigate = useNavigate();
  const [contratante, setContratante] = useState({});
  const [asegurados, setAsegurados] = useState([]);
  const [stepper, setStepper] = useState(primer);
  const [indice, setIndice] = useState(0);
  const [botonContinuar, setBtnContinuarDisabled] = useState(true);
  const [muestraGuardar, setMuestraGuardar] = useState(true);
  const formRef = useRef();
  const [pasosAlternos, setPasosAlternos] = useState(false);
  const [isMobiles, setIsMobiles] = useState((window.innerWidth < 1025) ? true : false);
  const [esCongenito, setEsCongenito] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [alreadySaveAilling, setAlreadySaveAilling] = useState(false);
  //const [isFirstAilling, setIsFirstAilling] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningMulti, setShowWarningMulti] = useState(false);
  const size = useWindowSize();

  const setPaso = (siguiente) => {
    actualizaPaso();
    if (pasosAlternos) {
      switch (siguiente) {
        case 3:
          setStepper(terceroAlt);
          break;
        case 4:
          setStepper(cuartoAlt);
          break;
        default:
          setStepper(segundoAlt);
          break;
      }
    } else {
      switch (siguiente) {
        case 2:
          setStepper(segundo);
          break;
        case 3:
          setStepper(tercero);
          break;
        case 4:
          setStepper(cuarto);
          break;
        default:
          setStepper(primer);
          break;
      }
    }
  };

  useEffect(()=>{
    if(stepper.paso === 3 && esCongenito){
      //Si tiene enfermedades congenitas
      const { asegurados } = contratante
      if(asegurados.length > 1){
        //Caso para varios asegurados
      }else{
        //Caso de un solo asegurado
        if(!asegurados[0].congenitos.length){
          if(alreadySaveAilling){
            handleContinuar()
            setShowWarning(false)
          }
        }
      }
    }
  },[esCongenito, alreadySaveAilling,])

  const handleSiguienteAsegurado = (indiceAsegurado) => {
    contratante.asegurados = asegurados;
    let next = null;
    if (indiceAsegurado < asegurados.length - 1) {
      next = asegurados.find(current => current.indice === (indiceAsegurado + 1));
    }
    if (next && validaMenorDe4(next) && stepper.paso === 1) {
      if (indiceAsegurado + 1 < asegurados.length - 1) {
        handleSiguienteAsegurado(indiceAsegurado + 1);
      }
      if (indiceAsegurado + 1 === asegurados.length - 1 && stepper.paso !== 4) {
        setIndice(0);
        setPaso(stepper.paso + 1);
      }
    } else {
      if (indiceAsegurado < asegurados.length - 1) {
        if(stepper.paso === 3 && esCongenito){
          if(alreadySaveAilling){
            setShowWarningMulti(false)
            setIndice(indiceAsegurado + 1);
            setAlreadySaveAilling(false)
            //setFormChanged(false)
          }else{
            setShowWarningMulti(true)
          }
        }else{
          setIndice(indiceAsegurado + 1);
        }
      } else if (indiceAsegurado === asegurados.length - 1 && stepper.paso !== 4) { 
        if(stepper.paso === 3 && esCongenito){
          if(alreadySaveAilling){
            setShowWarningMulti(false)
            setIndice(0);
            setPaso(stepper.paso + 1);
          }else{
            setShowWarningMulti(true)
          }
        }else{
          setIndice(0);
          setPaso(stepper.paso + 1);
        }
      } else if (indiceAsegurado === asegurados.length && stepper.paso !== 4) {
        setIndice(0);
        setPaso(stepper.paso + 1);
      }
      if (indiceAsegurado === asegurados.length - 1 && stepper.paso === 4) {
        //cierra los acordeones
        setIndice(indice+1);
        setBtnContinuarDisabled(false);
      } else if (indiceAsegurado === asegurados.length && stepper.paso === 4) {
        //cierra los acordeones
        setIndice(indice+1);
        setBtnContinuarDisabled(false);
      }
    }
    window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(contratante));
    Api.actualizaContratante(contratante.id, contratante)
      .then(res => {
      })
      .catch(error => {
        console.log(error);
      });
  };

  const actualizaPaso = () => {
    if (contratante.ultimoPaso < 8) {
      switch (stepper.paso) {
        case 1:
          contratante.ultimoPaso = 5;
          break;
        case 2:
          contratante.ultimoPaso = 6;
          break;
        case 3:
          contratante.ultimoPaso = 7;
          break;
        case 4:
          contratante.ultimoPaso = 8;
          break;
        default:
          break;
      }
    }
  };

  // const PasoActual = (props) => {
  //   return <props.componente asegurado={props.asegurado} siguientePaso={props.siguientePaso} disabled={props.disabled} botonGuardar={props.botonGuardar}/>;
  // };
  
  const handleContinuar = (continuar = false) => {
    if (formRef.current) {
      if(stepper.paso !== 3){
        formRef.current.handleSubmit();
      }
      if (formRef.current.isValid) {
        if (contratante.tipoFlujo === 1) {
          const contra = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
          setContratante(contra);
          const asegurados = ordenarAsc(contra.asegurados, 'indice');
          contra.asegurados = asegurados;
          setContratante(contra);
          setAsegurados(asegurados);
        }

        if(stepper.paso === 3 && formRef.current.isValid){
          if(!esCongenito){
            formRef.current.handleSubmit();
            return
          }

          if(alreadySaveAilling){
            if(continuar){
              formRef.current.handleSubmit();
            }
          }else{
            setShowWarning(true)
          }
        }
        if (stepper.paso === 4 && formRef.current.isValid) {
          contratante.ultimoPaso = 8;
          window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(contratante));

          contratante.asegurados.forEach(asegurado => {
            window.dataLayer.push({
              event: "medico_seguro_contratar",
              cotizacion: contratante.folio,
              deporte: asegurado?.deporte ? "Sí" : "No",
              fumar: Boolean(asegurado?.estilosVida.find(estiloVida => estiloVida.nombreEstilo === "Fumar")) ? "Sí" : "No",
              tomar: Boolean(asegurado?.estilosVida.find(estiloVida => estiloVida.nombreEstilo === "Beber")) ? "Sí" : "No",
              drogas: Boolean(asegurado?.estilosVida.find(estiloVida => estiloVida.nombreEstilo === "Drogarse")) ? "Sí" : "No",
              estatura: asegurado?.altura,
              enfermedad_congenita: asegurado?.congenitos?.length ? "Sí" : "No",
              hospitalizaciones_previas: Boolean(asegurado?.hospitalizaciones?.length)  ? "Sí" : "No" ,
              estado_actual: asegurado?.prevencionControl[0]?.resultado === "Todo en orden" ? "Al 100%" : "Sigo en tratamiento",
              otros_padecimiento: Boolean(asegurado?.hospitalizaciones?.length) ? asegurado?.hospitalizaciones : "No",
              boton_contratar: "continuar"
            })
          })
          Api.actualizaContratante(contratante.id, contratante)
            .then(res => {
              navigate('/contratacion');
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }
  };

  const validaMenorDe4 = (asegurado) => {
    return calcularEdad(new Date(asegurado.fechanacimiento)) < 4;
  };

  const actualizaContratante = (aseguradoPaso) => {
    const currentIndex = contratante.asegurados.findIndex((asegurado) => asegurado.id === aseguradoPaso.id);
    contratante.asegurados.splice(currentIndex, 1, aseguradoPaso);
    setContratante(contratante);
    setShowWarningMulti(false)
    window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(contratante));
  };

  const registroPasoUno = () => {
    return contratante.asegurados.findIndex((asegurado) => (asegurado.estilosVida.length > 0 && asegurado.descrDeporte)) > CMB_MENOS_UNO || contratante.ultimoPaso > 4;
  };

  const registroPasoTres = () => {
    return contratante.asegurados.findIndex((asegurado) => (asegurado.congenitos.length > 0 || asegurado.hospitalizaciones.length > 0)) > CMB_MENOS_UNO || contratante.ultimoPaso > 6;
  };

  const registroPasoCuatro = () => {
    return contratante.asegurados.findIndex((asegurado) => (asegurado.prevencionControl.length > 0)) > CMB_MENOS_UNO || contratante.ultimoPaso > 7;
  };

  useEffect(() => {
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contra = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
      setContratante(contra);
      const asegurados = ordenarAsc(contra.asegurados, 'indice');
      contra.asegurados = asegurados;
      setContratante(contra);
      setAsegurados(asegurados);
      if (contra.tipoFlujo === 1) {
        setMuestraGuardar(false);
        setBtnContinuarDisabled(false);
      }
      if (asegurados) {
        if (contra.tipoFlujo === 3) {
          //valida que sea niño pues el paso 1 no va para niño
          //pero si en la lista va otro mayor de 4 años si va
          //si todos los asegurados son menores de 4 años no deberia ir el paso 1 de plano para nadie
          const ninios = asegurados.filter(current => validaMenorDe4(current));
          if (ninios.length === asegurados.length) {//todos son menores de 4
            setStepper(segundoAlt);
            setPasosAlternos(true);
          } else {//no todos son menores de 4, necesitamos saber el siguiente indice de no menor para ponerlo en el paso 1
            const primerNoMenor = asegurados.find(current => !validaMenorDe4(current));
            if (primerNoMenor !== undefined) {
              setIndice(primerNoMenor.indice);
            } else {
              setIndice(0);
            }
          }
        }
      }
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);

  useEffect(() => {
    setIsMobiles((size.width <= 767)? true : false);
  }, [size]);
  

  return (
    <>
      <div className="container-wrapper">
        <Container maxWidth="xl" style={{ height: '100%' }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mX-25">
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} pr={1}>
              <div className="linea-amarilla">
                <img src={borderLineaUno} alt='' />
                <div className="text-ya">&iexcl;Ya mero terminamos!</div>
                <div className="text-dos">Te haremos unas preguntas muy r&aacute;pidas para saber qu&eacute; es de tu vida y tener tu perfil completo. (Nadie sabr&aacute; de esto 🤫)</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Typography sx={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                N&uacute;mero de cotizaci&oacute;n
                <BlueTooltip title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos." />
              </Typography>
              <Typography sx={{ background: "white", p: 1, pl: 2, borderRadius: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)", }} >
                {contratante?.folio || "0000000000"}
              </Typography>
            </Grid>
          </Grid>
          <div className="mX-25 frame-cuestionario">
            <div className="frame-cuestionario-detalle">
              <Stepper stepper={stepper} />
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="padding-preguntas">
                {asegurados && asegurados.map((asegurado, i) => (
                  <React.Fragment key={i}>
                    {(stepper.paso === 1 && !validaMenorDe4(asegurado)) &&
                      <PasoUno asegurado={asegurado} siguienteAsegurado={handleSiguienteAsegurado} disabled={i === indice}
                        botonGuardar={asegurados.length > 1} muestraGuardar={muestraGuardar} indice={({ indice: indice, aseLength: asegurados.length })}
                        actualizaContratante={actualizaContratante} registroPaso={registroPasoUno()} formRef={formRef}/>
                    }
                    {stepper.paso === 2 &&
                      <PasoDos asegurado={asegurado} siguienteAsegurado={handleSiguienteAsegurado} disabled={i === indice}
                        botonGuardar={asegurados.length > 1} muestraGuardar={muestraGuardar} actualizaContratante={actualizaContratante}
                        formRef={formRef} />
                    }
                    {stepper.paso === 3 &&
                    <>
                      <PasoTres asegurado={asegurado} siguienteAsegurado={handleSiguienteAsegurado} disabled={i === indice}
                        botonGuardar={asegurados.length > 1} muestraGuardar={muestraGuardar} actualizaContratante={actualizaContratante}
                        registroPaso={registroPasoTres()}
                        setEsCongenito={setEsCongenito}
                        cantidadCongenitos={contratante.asegurados[i].congenitos.length}
                        setAlreadySaveAilling={setAlreadySaveAilling}
                        showWarningMulti={showWarningMulti}
                        formRef={formRef} />
                        {
                          showWarning &&
                          <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert variant='filled' severity="error">Es necesario guardar su enfermedad congenita!</Alert>
                          </Stack>
                        }
                    </>
                      
                    }
                    {stepper.paso === 4 &&
                      <PasoCuatro asegurado={asegurado} siguienteAsegurado={handleSiguienteAsegurado} disabled={i === indice}
                        botonGuardar={asegurados.length > 1} muestraGuardar={muestraGuardar} actualizaContratante={actualizaContratante}
                        registroPaso={registroPasoCuatro()}
                        formRef={formRef} />
                    }
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </div>
          {isMobiles !== true ?
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100 pX-24">
              <Button variant="contained" disableElevation disabled={botonContinuar}
                className="boton-submit boton-form-seleccionado" onClick={()=>handleContinuar(true)}
              >
                Continuar
              </Button>
            </Grid>
            :
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className=" pX-24"
            >
              <Button variant="contained" disableElevation disabled={botonContinuar}
                className="boton-mobile boton-form-seleccionado" onClick={()=>handleContinuar(true)}
              >
                Continuar
              </Button>
            </Grid>
          }
        </Container>
      </div>
      
    </>
  );

}