import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { ReactComponent as LogoAxa } from "../../assets/landing/LogoAxa.svg";
import { ReactComponent as LogoGmx } from "../../assets/img/planesCotizacion/logo-general-de-salud.svg";
import { ReactComponent as LogoGnp } from "../../assets/landing/LogoGnp.svg";
import { ReactComponent as LogoMapfre } from "../../assets/landing/LogoMapfre.svg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Grid}`,
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: "transparent",
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    color: "#595959",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(0deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(-1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, 0)",
}));

export default function PreguntasFrecuentes() {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Container maxWidth="xl" sx={{ padding: 0 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="textPF">Preguntas frecuentes</div>
        <div style={{ paddingTop: "20px", marginBottom: "128px" }}>
          <Accordion
            key="acc1"
            expanded={expanded === `panelacc1`}
            onChange={handleChange(`panelacc1`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc1` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc1d-content`}
              id={`panelacc1d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿A dónde telefoneo si un día tengo un accidente o me siento mal?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "24px" }}>
                ¡Llámale a tu aseguradora de inmediato! Aquí te dejamos los
                teléfonos:
              </Typography>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  rowGap: "24px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoAxa style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    800 001 8700
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoGmx style={{ height: "35px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    800 72 72583
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoGnp style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    800 001 8700
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoMapfre style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    55 5246 7502 ó 800 365 00 24
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            key="acc2"
            expanded={expanded === `panelacc2`}
            onChange={handleChange(`panelacc2`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc2` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc2d-content`}
              id={`panelacc2d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿Qué hago si tengo una emergencia médica?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Recuerda que primero lo primero, llamar a la aseguradora, confirmar tu folio para el acceso al hospital de acuerdo a tu plan contratado.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    ¡Córrele a un hospital del nivel que te toca según tu
                    pólica! Puedes checar la lista de hospitales echándole un
                    telefonazo al centro de atención de tu aseguradora.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Cuando llegues al hospital, presenta tu credencial digital
                    de la aseguradora y tu identificación oficial vigente.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Para que tu aseguradora se encargue del pago directo, tu
                    hospitalización tiene que superar las 24 horas.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    ¡Indispensable! Los gastos médicos que te cubrirá tu
                    aseguradora serán sólo de padecimientos amparador por la
                    póliza. Por eso es importantísimo contar con un diagnóstico
                    definitivo y los estudios que lo corroboren.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Por políticas de algunos hospitales, puede ser que te pidan
                    un depósito o una tarjeta de crédito.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            key="acc3"
            expanded={expanded === `panelacc3`}
            onChange={handleChange(`panelacc3`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc3` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc3d-content`}
              id={`panelacc3d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿Qué debo hacer cuando ya me den de alta?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                En cuanto el Doc firme que ya puedes salir del hospital, algún
                familiar debe checar que el hospital sí mande toda la info
                médica y administrativa a la aseguradora para ver qué onda con
                el dictamen final. Este trámite tarda como 3 horas en promedio,
                ¡así que ten paciencia!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            key="acc4"
            expanded={expanded === `panelacc4`}
            onChange={handleChange(`panelacc4`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc4` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc4d-content`}
              id={`panelacc4d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿Qué le toca pagar a la aseguradora y qué a mí?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    A la aseguradora le toca cubrir todo lo relacionado con tu
                    enfermedad o accidente, como la hospitalización, los
                    honorarios médicos y los medicamentos, mientras los gastos
                    no superen tu suma asegurada.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    A ti te toca el deducible, el coaseguro, lo que no esté
                    relacionado con el padecimiento y los gastos personales,
                    como pañales, agua, orinal, comida de quien te acompañe,
                    etc.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            key="acc5"
            expanded={expanded === `panelacc5`}
            onChange={handleChange(`panelacc5`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc5` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc5d-content`}
              id={`panelacc5d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿Cómo puedo saber si mi seguro aplica o no en cada caso?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Hay padecimientos que tienen tiempo de espera. Eso no
                    significa que para que tu aseguradora pueda cubrirte, tienes
                    que haber estado asegurado continuamente por cierto tiempo.
                    Si tienes duda de esto, ¡échanos un telefonazo y nosotros te
                    decimos qué onda!
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Si tu padecimiento empezó antes de que contrataras tu
                    seguro, la aseguradora no te va a poder echar la mano con
                    ese gasto.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Si no es una súper emergencia, ¡mejor programa tu ingreso al
                    hospital! Así puedes estar segur@ de que tu seguro te va a
                    cubrir.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            key="acc6"
            expanded={expanded === `panelacc6`}
            onChange={handleChange(`panelacc6`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panelacc6` ? <RemoveIcon /> : <AddIcon />
              }
              aria-controls={`panelacc6d-content`}
              id={`panelacc6d-header`}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19.5px",
                }}
              >
                ¿Qué hago si me enfermo o me pasa algo estando fuera de México?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                ¡Primero lo primero! Checa si tu póliza tiene cobertura
                internacional, porque si no, tu seguro no te va a poder cubrir.
                ¿Sí la tiene? ¡Entonces échale una llamada a tu aseguradora!:
              </Typography>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  rowGap: "24px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoAxa style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    01 888 293 7221
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoGnp style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    01 800 807 5697
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoMapfre style={{ height: "25px" }} />
                  <Typography
                    sx={{
                      fontSize: "clamp(12px, 2vh, 14px)",
                      fontWeight: "700",
                    }}
                  >
                    55 5480 3814 ó 800 71 913 00
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Typography style={{ textAlign: "center", paddingTop: "16px" }}>
            Para más info consulta{" "}
            <a
              href="https://inter.mx/terminos-condiciones"
              style={{ textDecoration: "underline #009DD0", color: "#009DD0" }}
            >
              términos y condiciones
            </a>
          </Typography>
        </div>
      </Grid>
    </Container>
  );
}
