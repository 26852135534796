import { Form, Formik } from "formik";
import './TarjetaAgregar';
import { validationEmbarazoForm, validationForm } from "./ValidationForm"
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CustomTextField from "../CustomFields/CustomTextField";
import CustomSelect from "../CustomFields/CustomSelect";
import MenuItem from '@mui/material/MenuItem';
import { EDAD_MUJER_MAX, EDAD_MUJER_MIN, EXCLUSION_E, EXCLUSION_M, FECHA_VALIDA, INTER_CONTRATANTE, TIPO_FLUJO } from "../Utils/Constantes";
import { useEffect, useState } from "react";
import CustomDatePickerLab from "../CustomFields/CustomDatePickerLab";
import CustomErrorMessage from "../CustomFields/CustomErrorMessage";
import clsx from 'clsx';
import { calcularEdad, generoValueToService, serviceToGeneroValue } from "../Utils/Utils";
import Api from "../Api/Api";
import { CircularProgress } from "@mui/material";
import BlueTooltip from "../Utils/BlueTooltip";

export default function AseguradoAdicional(props) {

  const [contratante, setContratante] = useState({});
	const [isLoading, setLoading] = useState(true);  
	const [parentescos, setParentescos] = useState([]);
	const [pasoEmbarazo, setPasoEmbarazo] = useState((props.asegurado && serviceToGeneroValue(props.asegurado.agregado.genero) === 1 ? 
  (calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) > EDAD_MUJER_MIN && calcularEdad(new Date(props.asegurado.agregado.fechanacimiento)) < EDAD_MUJER_MAX ? true : false) : false));
  
  const handleOnChangeParentesco = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    if(fecha instanceof Date && !isNaN(fecha)){
      setFieldValue("fechanacimiento",fecha);
    } else {
      setFieldError("fechanacimiento",FECHA_VALIDA);
    }
  };

	const handleGenero = (genero, setFieldValue, values) => {
		setFieldValue('embarazo',null);
		setFieldValue('cp','');
		setPasoEmbarazo(false);
		setFieldValue("genero", genero);
		if(genero === 0){
			setFieldValue('cp','');
			setPasoEmbarazo(false);
		} else {
			if( calcularEdad(new Date(values.fechanacimiento)) > EDAD_MUJER_MIN && calcularEdad(new Date(values.fechanacimiento)) < EDAD_MUJER_MAX ){
				setPasoEmbarazo(true);
        setFieldValue("embarazo", null);
			}
		}
	};

	const handleEmbarazo = (embarazo, setFieldValue) => {
		setFieldValue("embarazo", embarazo);
	};
  
  const getTextValue = (idParentesco) => {
    const parentesco = parentescos.find(parentesco => parentesco.id === idParentesco);
    return parentesco.nombreParentesco;
  };
  
  const getIdFromCatalogoParentesco = (nombreParentesco) => {
    const parentesco = parentescos.find(parentesco => parentesco.nombreParentesco === nombreParentesco);
    return parentesco.id;
  };
  
  const handleContinuar = (values, setSubmitting) => {
    let exclusion = 0;
    const commonCP = getCP();
    const addAsegurado = {
      nombre: values.nombre,
      fechanacimiento: new Date(values.fechanacimiento),
      genero: generoValueToService(values.genero),
      parentesco: getTextValue(values.parentesco),
      embarazo: handleValuesToEmbarazo(values),
      titular: false,
      cp: commonCP,
      indice: (props.asegurado ? props.asegurado.indice : contratante.asegurados.length),
    }
    let resultado = [];
    if(values.genero === 1 && addAsegurado.embarazo) {
      window.sessionStorage.setItem(EXCLUSION_E,JSON.stringify(addAsegurado));
      exclusion = 2;
    }
    if(props.asegurado){
      if(calcularEdad(new Date(addAsegurado.fechanacimiento)) > 70) {
        window.sessionStorage.setItem(EXCLUSION_M,JSON.stringify(addAsegurado));
        exclusion = 1;
        //TODO: en edicion, se debe quitar de contratante.asegurados con el props.asegurado.indice
      } else {
        contratante.asegurados.splice(props.asegurado.indice, 1, addAsegurado);
      }
      resultado = contratante.asegurados;
    } else {
      if(calcularEdad(addAsegurado.fechanacimiento) > 70) {
        window.sessionStorage.setItem(EXCLUSION_M,JSON.stringify(addAsegurado));
        exclusion = 1;
        resultado = contratante.asegurados;
      } else {
        resultado = contratante.asegurados.concat(addAsegurado);
      }
    }
    contratante.asegurados = resultado;
    window.sessionStorage.setItem(INTER_CONTRATANTE,JSON.stringify(contratante));
    //setSubmitting(false);
    props.handleClose(exclusion);
  };
  
  const getCP = () => {
    if(window.sessionStorage.getItem(TIPO_FLUJO)){
      const tipoFlujo = JSON.parse(window.sessionStorage.getItem(TIPO_FLUJO));
      let cp = "";
      switch (tipoFlujo) {
        case 1:
        case 2:
          cp = contratante.cp;
          break;
        case 3:
          const titular = contratante.asegurados.find(asegurado => asegurado.titular === true);
          cp = titular.cp;
          break;
        default:
          cp = contratante.cp;
          break;
      }
      return cp;
    } else {
      return contratante.cp;
    }
  }

  const handleValuesToEmbarazo = (values) => {
    if(values.genero === 1){
      if(calcularEdad(new Date(values.fechanacimiento)) < 10 || calcularEdad(new Date(values.fechanacimiento)) > 55){
        return false;
      } else {
        return (values.embarazo !== "" ? values.embarazo : null);
      }
    }
  };

  useEffect(() => {
    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      setContratante(JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE)));
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[]);

  useEffect(() => {
    window.dataLayer.push ({
      'event': 'form_step_3_viewADI'
    });
    Api.getParentescos()
    .then(res => {
      setParentescos(res.data);
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
    });
    return function cleanup() {
      setParentescos([]);
    };
  },[]);
  
  return(
    <>
      { isLoading ? <CircularProgress /> : 
        <Formik validateOnChange={true}
          initialValues={{
            nombre: (props.asegurado ? props.asegurado.agregado.nombre : ''),
            fechanacimiento: (props.asegurado ? props.asegurado.agregado.fechanacimiento : new Date()),
            genero: (props.asegurado ? serviceToGeneroValue(props.asegurado.agregado.genero) : null),
            parentesco: (props.asegurado ? getIdFromCatalogoParentesco(props.asegurado.agregado.parentesco) : -1),
            embarazo: (props.asegurado ? props.asegurado.agregado.embarazo : null),
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleContinuar(values, setSubmitting);
          }}
          validationSchema={pasoEmbarazo ? validationEmbarazoForm : validationForm}>
          {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
            <Form>
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} className="asegurado-modal">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-0px-modal">
                  <div className="label-asegurado mX-10">¿Cu&aacute;l es su nombre?</div>
                  <CustomTextField id="nombre" name="nombre" type="text" placeholder="Nombre" className="grid-item-input"/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-15px-modal">
                  <div className="label-asegurado mX-10">¿Cu&aacute;ndo naci&oacute;?</div>
                  <CustomDatePickerLab id="fechanacimiento" name="fechanacimiento" type="text" placeholder="Fecha Nacimiento" 
                    className="grid-item-input w-100"
                    disableFuture onChange={(newValue) => 
                      handleChangeFechaNacimiento(newValue, setFieldValue, setFieldError)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-15px-modal">
                  <p className="p-genero-tooltip">¿C&uacute;al es su sexo de nacimiento?
                    <BlueTooltip
                      title="Sabemos que esta pregunta puede ser complicada, pero para las aseguradora es un dato muy importante para saber cómo protegerte.">
                    </BlueTooltip>
                  </p>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Button variant="outlined" disableElevation 
                        className={clsx({"boton-form-seleccionado": (values.genero === 0)}, {"boton-form w-100":true})}
                        onClick={(event) => handleGenero(0, setFieldValue, values)} disabled={isSubmitting}>
                        Hombre
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex flex-end">
                      <Button variant="outlined" disableElevation
                        className={clsx({"boton-form-seleccionado": (values.genero === 1)}, {"boton-form w-100":true})}
                        onClick={(event) => handleGenero(1, setFieldValue, values)} disabled={isSubmitting}>
                        Mujer
                      </Button>
                    </Grid>
                    <CustomErrorMessage name="genero"/>
                  </Grid>                
                </Grid>
                {pasoEmbarazo && 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-15px-modal">
                    <p className="mX-10">¿Est&aacute; embarazada?</p>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Button variant="outlined" disableElevation 
                          className={clsx({"boton-form-seleccionado": (values.embarazo)}, {"boton-form w-100":true})}
                          onClick={(event) => handleEmbarazo(true, setFieldValue)}>
                          Si
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex flex-end">
                        <Button variant="outlined" disableElevation
                          className={clsx({"boton-form-seleccionado": (values.embarazo === false)}, {"boton-form w-100":true})}
                          onClick={(event) => handleEmbarazo(false, setFieldValue)}>
                          No
                        </Button>
                      </Grid>
                      <CustomErrorMessage name="embarazo"/>
                    </Grid>
                  </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-15px-modal">
                  <p className="mX-10">¿Qu&eacute; parentesco tienen?</p>
                  <CustomSelect id="parentesco" name="parentesco" className="grid-item-select w-100"
                    onChange={(event) => handleOnChangeParentesco(event, setFieldValue)}>
                    <p className="mX-10">¿Qu&eacute; parentesco tienen?</p>
                    <MenuItem value={-1}>Seleccionar</MenuItem>
                    {parentescos.map((parentesco, i) => (
                      <MenuItem key={i} value={parentesco.id}>{parentesco.nombreParentesco}</MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 3, md: 1, lg:1, xl:1}} >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                      <Button variant="outlined" disableElevation onClick={props.handleClose} className="boton-accion-modal" disabled={isSubmitting}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                      <Button variant="contained" disableElevation onClick={submitForm} className="boton-accion-modal" disabled={isSubmitting}>Agregar</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </>
  );
}