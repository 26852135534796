import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.css";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";

const Modal = ({ onClose, setShowModal }) => {
  const [promotionsData, setPromotionsData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const isDesktop = width > 500;

  useEffect(() => {
    // Función para hacer la solicitud a la URL
    const getPromotions = async () => {
      try {
        const response = await axios.get(
          "https://demos.inter.mx/v1/catalogs/promo-banner?",
          { params: { tag: "gmm" } }
        );

        const data = response?.data?.data?.filter(item => item.title.includes("Banner")) ?? [];

        if (data.length > 0) {
         /**
           * Filtro de fecha de vencimiento
           */
          let promotionData = [];
         data.map((item) => {
           // Promocion en curso
           if (new Date(item.endDate) > new Date()) {
            promotionData.push(item);
           }
         });

         if (promotionData.length > 0) {
          //console.log(promotionsData);
           setPromotionsData(promotionData);
         } else {
           setShowModal(false);
         }
       } else {
         setShowModal(false);
       }
      } catch (error) {
        console.error("Error al hacer la solicitud:", error);
        setShowModal(false);
      }
    };

    getPromotions(); // Llama a la función para obtener los datos solo una vez al montar el componente

    window.scrollTo(0, 0);
    // Función para actualizar el estado cuando cambia el tamaño de la ventana
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    // Agregar un listener para el evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 1, // Cambia este número según la cantidad de imágenes que desees mostrar a la vez
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500, // Velocidad de transición en milisegundos
    autoplaySpeed: 3500, // Tiempo entre cada transición en milisegundos
    arrows: true, // Mostrar flechas de navegación
  };

  const handleImageClick = (data) => {
    // Cambia la ubicación de la página al hacer clic en la imagen
    let tag = data.tag;
    switch (tag) {
      case 'coche':
        window.open('https://cocheseguro.inter.mx/inicio', '_blank');
        break;
      case 'gmm':
        window.open('https://medicoseguro.inter.mx/inicio', '_blank');
        break;
      case 'mascota':
        window.open('https://mascotasegura.inter.mx/', '_blank');
        break;
      case 'tr1s':
          window.open('https://teregalamosunseguro.inter.mx/', '_blank');
          break;
      default:
        break;
    }
  };

  const handleCloseClick = () => {
    onClose();
  };

  return (
    <>
    {promotionsData.length > 0 && (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-button-close mb-2">
          <IconButton
            type="button"
            sx={{ p: "12px", color: "#212121" }}
            aria-label="closeModal"
            onClick={handleCloseClick}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
        </div>
        <div className="carousel-container">
          <Slider {...settings}>
            {promotionsData.map((data, index) => {
              return (
                  <img
                    src={isDesktop ? data.image : data.url}
                    alt={`Anuncio ${index}`}
                    className="carousel-image"
                    // onClick={() => handleImageClick(data)}
                    key={index}
                  />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
    )}
    </>
  );
};

export default Modal;