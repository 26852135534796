import { useEffect, useState, useContext } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material"
import * as React from "react"
import CustomTextField from "../CustomFields/CustomTextField"
import {
  validationFormPasoTres,
  validationFormPasoTresMenor,
} from "./ValidationForm"
import { Form, Formik } from "formik"
import CustomSelect from "../CustomFields/CustomSelect"
import {
  CMB_MENOS_UNO,
  INTER_CONTRATANTE,
  FECHA_VALIDA,
  ocupaciones,
} from "../Utils/Constantes"
import CustomDatePickerLab from "../CustomFields/CustomDatePickerLab"
import { ordenarAsc } from "../Utils/Utils"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import borderLineaDos from "../../assets/img/cuestionario/border-rounded-linea2.svg"
import Api from "../Api/Api"
import Loading from "../Commons/Loader"
import { differenceInYears } from "date-fns"
import { handleMaxLength } from "../Utils/FieldValidationFunctions"
import { Context } from "./Contratacion"
export default function PasoTres(props) {
  const { setIsSubmitting } = useContext(Context)
  const [contratante, setContratante] = useState({})
  const [asegurados, setAsegurados] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [parentescos, setParentescos] = useState([])
  const [indice, setIndice] = useState(1)
  const [openLoader, setOpenLoader] = React.useState(false)
  const handleGuardar = (values, setSubmitting, i) => {
    contratante.asegurados[i].nombre = values.nombre
    contratante.asegurados[i].apaterno = values.apellidop
    contratante.asegurados[i].amaterno = values.apellidom
    contratante.asegurados[i].parentesco = values.parentesco
    contratante.asegurados[i].rfc = values.rfc
    contratante.asegurados[i].ocupacion = values.ocupacion
    contratante.asegurados[i].telefono = values.telefono
    contratante.asegurados[i].correo = values.correo
    contratante.ultimoPaso = 11
    window.dataLayer.push({
      event: "form_step_9_submitADI",
      cp: "",
      rfc: values.rfc,
      curp: "",
      nombre_completo:
        contratante.asegurados[i].nombre +
        " " +
        contratante.asegurados[i].apaterno +
        " " +
        contratante.asegurados[i].amaterno,
    })
    Api.actualizaAsegurado(contratante.id, contratante.asegurados[i])
      .then((res) => {
        Api.getContratante(contratante.folio)
          .then((res) => {
            setOpenLoader(false)
            window.sessionStorage.setItem(
              INTER_CONTRATANTE,
              JSON.stringify(res.data.contratante)
            )
            setSubmitting(false)
            if (
              asegurados &&
              asegurados.length > 1 &&
              indice < asegurados.length
            ) {
              setIndice(indice + 1)
            } else if (asegurados && indice === asegurados.length) {
              props.handleContinuar()
            }
          })
          .catch((error) => {
            setOpenLoader(false)
            setSubmitting(false)
            console.log(error)
          })
      })
      .catch((error) => {
        setOpenLoader(false)
        setSubmitting(false)
        console.log(error)
      })

    if (indice === asegurados.length - 1) {
      setIsSubmitting(false)
    }
  }
  const handleOnChangeGenero = (event, setFieldValue) => {
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
  }
  const handleOnChangeParentesco = (event, setFieldValue) => {
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
  }
  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha)
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA)
    }
  }

  const handleOnChangeOcupacion = (event, setFieldValue) => {
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
  }

  useEffect(() => {
    setIsSubmitting(true)
    window.dataLayer.push({
      event: "form_step_9_viewADI",
    })
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contra = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE))
      const asegurados = ordenarAsc(contra.asegurados, "indice")
      contra.asegurados = asegurados
      setContratante(contra)
      setAsegurados(asegurados)
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [])

  useEffect(() => {
    Api.getParentescos()
      .then((res) => {
        setParentescos(res.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const validaEdad = (aseguradoFecha) => {
    const year = differenceInYears(new Date(), new Date(aseguradoFecha))
    return year >= 18
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <span className="text-preguntas">
          Y ahora, vamos con los asegurados adicionales, &iquest;va?
        </span>
        <br />
        <br />
      </Grid>
      {!isLoading &&
        asegurados.map((asegurado, i) => (
          <React.Fragment key={i}>
            {!asegurado.titular && (
              <Accordion
                disabled={i !== indice}
                square={true}
                expanded={i === indice} className="accordion-w100"
              >
                <AccordionSummary
                  expandIcon={asegurado.titular ? "" : <ExpandMoreIcon />}
                  aria-controls="paso3-content"
                  id="paso3-header"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="linea-amarilla">
                      <img src={borderLineaDos} alt="" />
                      {i < asegurados.aseLength && (
                        <img src={borderLineaDos} alt="" />
                      )}
                      <div className="titular">
                        {asegurado.titular ? "Titular" : "Asegurado adicional"}
                      </div>
                      <div className="nombre">{asegurado.nombre}</div>
                    </div>
                    <hr className="divider" />
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Formik
                    validateOnChange={true}
                    initialValues={{
                      nombre: asegurado.nombre,
                      apellidop:
                        asegurado.apaterno !== null ? asegurado.apaterno : "",
                      apellidom:
                        asegurado.amaterno !== null ? asegurado.amaterno : "",
                      fechanacimiento:
                        asegurado.fechanacimiento !== null
                          ? new Date(asegurado.fechanacimiento)
                          : "",
                      genero: asegurado.genero !== null ? asegurado.genero : "",
                      parentesco:
                        asegurado.parentesco !== null
                          ? asegurado.parentesco
                          : "",
                      rfc: asegurado.rfc !== null ? asegurado.rfc : "",
                      ocupacion: validaEdad(asegurado.fechanacimiento)
                        ? asegurado.ocupacion !== null
                          ? asegurado.ocupacion
                          : -1
                        : asegurado.ocupacion !== null
                        ? asegurado.ocupacion
                        : "",
                      telefono: asegurado.telefono || "",
                      correo: asegurado.correo || "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true)
                      setOpenLoader(true)
                      handleGuardar(values, setSubmitting, i)
                    }}
                    validationSchema={
                      validaEdad(asegurado.fechanacimiento)
                        ? validationFormPasoTres
                        : validationFormPasoTresMenor
                    }
                  >
                    {({
                      values,
                      submitForm,
                      setFieldValue,
                      handleChange,
                      errors,
                      isSubmitting,
                      setFieldError,
                      touched,
                      setFieldTouched,
                    }) => (
                      <Form>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ mt: 2 }}>
                              <Stack
                                direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                spacing={2}
                              >
                                <CustomTextField
                                  id="nombre"
                                  name="nombre"
                                  placeholder="Nombre"
                                />
                                <CustomTextField
                                  id="apellidop"
                                  name="apellidop"
                                  placeholder="Primer apellido"
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Stack
                                direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                spacing={2}
                              >
                                <CustomTextField
                                  id="apellidom"
                                  name="apellidom"
                                  placeholder="Segundo apellido"
                                />
                                <FormControl fullWidth disabled>
                                  <CustomDatePickerLab
                                    id="fechanacimiento"
                                    name="fechanacimiento"
                                    type="text"
                                    disabled
                                    placeholder="Fecha Nacimiento"
                                    className="grid-item-input w-100"
                                    disableFuture
                                    onChange={(newValue) =>
                                      handleChangeFechaNacimiento(
                                        newValue,
                                        setFieldValue,
                                        setFieldError
                                      )
                                    }
                                  />
                                </FormControl>
                              </Stack>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Stack
                                direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                spacing={2}
                              >
                                <FormControl fullWidth>
                                  <CustomSelect
                                    id="genero"
                                    name="genero"
                                    disabled
                                    onChange={(event) =>
                                      handleOnChangeGenero(event, setFieldValue)
                                    }
                                  >
                                    <MenuItem value={CMB_MENOS_UNO}>
                                      Sexo de nacimiento
                                    </MenuItem>
                                    <MenuItem value={"Masculino"}>
                                      Hombre
                                    </MenuItem>
                                    <MenuItem value={"Femenino"}>
                                      Mujer
                                    </MenuItem>
                                  </CustomSelect>
                                </FormControl>
                                <FormControl fullWidth>
                                  <CustomSelect
                                    id="parentesco"
                                    name="parentesco"
                                    className="grid-item-select w-100"
                                    onChange={(event) =>
                                      handleOnChangeParentesco(
                                        event,
                                        setFieldValue
                                      )
                                    }
                                  >
                                    <p className="mX-10">
                                      &iquest;Qu&eacute; parentesco tienen?
                                    </p>
                                    <MenuItem value={-1}>Seleccionar</MenuItem>
                                    {parentescos.map((parentesco, i) => (
                                      <MenuItem
                                        key={i}
                                        value={parentesco.nombreParentesco}
                                      >
                                        {parentesco.nombreParentesco}
                                      </MenuItem>
                                    ))}
                                  </CustomSelect>
                                </FormControl>
                              </Stack>
                            </Box>
                            {validaEdad(asegurado.fechanacimiento) && (
                              <Box sx={{ mt: 2 }}>
                                <Stack
                                  direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                  spacing={2}
                                >
                                  <CustomTextField
                                    id="rfc"
                                    name="rfc"
                                    placeholder="RFC"
                                  />
                                  <FormControl fullWidth>
                                    <CustomSelect
                                      id="ocupacion"
                                      name="ocupacion"
                                      onChange={(event) =>
                                        handleOnChangeOcupacion(
                                          event,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <MenuItem value={CMB_MENOS_UNO}>
                                        Ocupación
                                      </MenuItem>
                                      {ocupaciones.map((ocupacion, i) => (
                                        <MenuItem
                                          key={i}
                                          value={ocupacion.valor}
                                        >
                                          {ocupacion.valor}
                                        </MenuItem>
                                      ))}
                                    </CustomSelect>
                                  </FormControl>
                                </Stack>
                                <Stack
                                  direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                  spacing={2}
                                  mt={1}
                                >
                                  <CustomTextField
                                    id="correo"
                                    name="correo"
                                    type="text"
                                    placeholder="Correo electr&oacute;nico"
                                  />
                                  <CustomTextField
                                    id="telefono"
                                    name="telefono"
                                    type="text"
                                    placeholder="Celular"
                                    onChange={(event) =>
                                      handleMaxLength(
                                        event,
                                        setFieldValue,
                                        "telefono",
                                        10
                                      )
                                    }
                                  />
                                </Stack>
                              </Box>
                            )}
                            {/*} <Box sx={{ mt: 2 }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                              <CustomTextField id="paso" name="peso" placeholder="Peso" />
                              <CustomTextField id="estatura" name="estatura" placeholder="Estatura" />
                            </Stack>
                          </Box>*/}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ mt: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={submitForm}
                              >
                                Guardar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </AccordionDetails>
              </Accordion>
            )}
          </React.Fragment>
        ))}
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </>
  )
}
