import * as React from "react"
import { Button, Dialog, Grid, Stack } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ContactosModal from "./ContactosModal"
import sx from './styles'
export default function AyudaModal(props) {
  const { handleClose, isVisible = false } = props
  const [showContactModal, setShowContactModal] = React.useState(false)

  const handleClickSurfly = () => {
  }

  return (
    <Dialog
      open={isVisible}
      disableEscapeKeyDown={true}
      maxWidth="sm"
      fullWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={sx.iconClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className="modal-individual-padding">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={3}
          >
            <span className="modal-titulo">¿No sabes qué onda?</span>
            <span className="modal-text">
              ¡Tú tranqui! En un momentito te contactaremos por acá para
              ayudarte.
            </span>
            <span className="modal-text-dos">
              ¡No cierres la ventana ni tu navegador!
            </span>
            <Button
              variant="contained"
              sx={sx.valeBtn}
              onClick={handleClickSurfly}
            >
              ¡Sale vale!
            </Button>
            <Button
              variant="text"
              sx={sx.anotherWayBtn}
              onClick={() => setShowContactModal(!showContactModal)}
            >
              Mejor por otro medio de contacto
            </Button>
            <iframe
              id="surfly-api-frame"
              title="surfly-api-frame"
              name="surfly-api-frame"
              className="surfly-button surfly-button-position-bottomleft"
              scrolling="no"
              aria-hidden="true"
            ></iframe>
          </Stack>
        </Grid>
      </Grid>
      {showContactModal && (
        <ContactosModal
          isVisible={showContactModal}
          handleClossAll={handleClose}
          isGoBack
          handleClose={() => setShowContactModal(!showContactModal)}
          sxProps={sx.modalContacsSecond}
        />
      )}
    </Dialog>
  )
}
