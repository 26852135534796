import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import Rodolfo6 from '../../assets/img/contratacion/Rodolfo6.png'
import { INTER_CONTRATANTE } from '../Utils/Constantes';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Divider} from '@mui/material';
import './Contratacion.css';

export default function Gracias(props) {
  const navigate = useNavigate();

  const handleSalir = () => {
    const contratante = window.sessionStorage.getItem(INTER_CONTRATANTE);

    window.dataLayer.push({
      event: "medico_seguro_datos_contratante",
      boton_terminar: "Sale vale",
      cotizacion: contratante.folio
    })

    sessionStorage.clear();
    navigate('/inicio');
  };

  useEffect(() => {
    window.dataLayer.push ({
      'event': 'form_step_12_view'
    });
  }, []);

  return (
    <div className="container-wrapper">
      <Container maxWidth="xl">
        <Box className='mX-30'>
          <Grid container direction="row" justifyContent="center" alignItems="center"
            sx={{ maxHeight: '590px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} >
              <div className="imagen-flujo">
                <img src={Rodolfo6} alt='' />
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem className="vertical-divider" />
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} p={3} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                  <p className="text-ya">¡Ya terminamos!</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
                  <span className='text-maximo'>
                  Ahora toca esperar tantito. ¡Pero no desesperes! En 48 horas tu asesor te estará contactando para decirte qué onda con tu contratación, ¿va?
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                  <Button variant="contained" disableElevation
                    className="boton-para w-100"
                    onClick={handleSalir}>
                    ¡Sale vale!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}