import { Box, Grid, Typography } from "@mui/material";
import IconPhone from "./Icons/IconPhone";
import IconWht from "./Icons/IconWht";
import IconEmail from "./Icons/IconEmail";
import antiFraImg from "../../assets/antifraudeImagen/homeFraude.svg";

const AntiFraudHome = (props) => {
  return (
    <Box
      sx={{
        marginTop: 6,
        marginBottom: 6,
        backgroundColor: "#F2FAFC",
        padding: "40px clamp(40px, 10vw, 120px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        direction={{ xs: "column", sm: "row", md: "row" }}
        display={"flex"}
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={4}
          xl={3}
          sx={{
            display: "flex",
            marginBottom: { xs: 5, md: 0 },
            paddingRight: { xs: 0, md: 5 },
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            component="img"
            src={antiFraImg}
            alt="antiFraImg"
            width={{ xs: "135px" }}
            height={{ xs: "148px" }}
            sx={{
              display: "flex",
              paddingTop: { xs: 0, sm: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={5} xl={4}>
          <Typography
            component="h2"
            className="section-title"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "20px", sm: "20px", md: "22px", lg: "28px" },
              lineHeight: "34px",
              textAlign: { xs: "center", sm: "left", md: "left" },
            }}
          >
            ¡Contratar en inter.mx es seguro, seguro!
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography
              variant="h5"
              style={{
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Mantenemos tu información protegida. Recuerda que en inter.mx
              nunca te pediremos pagos a cuentas de terceros ni transferencias.
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              height: "auto",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "left", md: "left" },
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Si tienes alguna duda contáctanos a:
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              height: "auto",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "left", md: "left" },
            }}
          >
            <IconWht />
              <Box component={'a'} sx={{
                  marginLeft: 1,
                  fontSize: '14px',
                  textAlign: 'left',
                  color: '#42b6da',
                  textDecoration:'none'
              }}
                   href='https://api.whatsapp.com/send/?phone=5547420824&text&type=phone_number&app_absent=0'
                   target='_blank'
                   rel="noreferrer"
              >
                  55 4742 0824
              </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              height: "auto",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "left", md: "left" },
            }}
          >
            <IconPhone />

              <Box component={'a'} sx={{
                  marginLeft: 1,
                  fontSize: '14px',
                  textAlign: 'left',
                  color: '#42b6da',
                  textDecoration:'none'
              }}
                   href='tel:5544246837'
                   target='_blank'
                   rel="noreferrer"
              >
                  55 442 46837
              </Box>
          </Box>

            <Box
                sx={{
                    mt: 2,
                    display: "flex",
                    height: "auto",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: { xs: "center", sm: "left", md: "left" },
                }}
            >
                <IconEmail />
                <Box component={'a'} sx={{
                    marginLeft: 1,
                    fontSize: '14px',
                    textAlign: 'left',
                    color: '#42b6da',
                    textDecoration:'none'
                }}
                     href='mailto:hola@inter.mx' target='_blank' rel="noreferrer"

                >
                    hola@inter.mx
                </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              height: "auto",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "left", md: "left" },
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Estamos siempre para ayudarte
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AntiFraudHome;
