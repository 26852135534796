import { createContext, useEffect, useState } from "react"
import { Button, Container, Grid } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import borderLineaUno from "../../assets/img/cuestionario/border-rounded-linea1.svg"
import PasoFirmatres from "./PasoFirmaTres"
import Api from "../Api/Api"
import EventEmitter from "../services/EventEmitter"
import {
  pasoFirmaCAF2,
  pasoFirmaCAF3,
  pasoFirmaFlujo1,
  pasoFirmaSAF2,
  pasoFirmaSAF3,
} from "../Utils/Constantes"
import Stepper from '../Commons/Stepper';
export const Context = createContext();

const PasoFirmaMobile = (props) => {
  const { isMobile } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState()
  const navigate = useNavigate()
  const { folio } = useParams()
  const [stepper, setStepper] = useState(pasoFirmaFlujo1)

  useEffect(() => {
    if (!isMobile && folio) navigate("/")
    Api.getContratante(folio)
      .then((res) => {
        const result = res?.data?.contratante
        setData(result)
        // window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(result))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleSave = async (signature) => {
    const res = await Api.actualizaContratante(data.id, {
      ...data,
      firma: signature,
      ultimoPaso: 12,
    })
    if (res.status === 200) {
      setTimeout(() => {
        navigate("/terminamos-contratacion")
      }, 3000)
    }
  }

  const handleContinue = () => {
    EventEmitter.emit("signature-event")
  }

  useEffect(() => {
    if (data) {
      switch (data.tipoFlujo) {
        case 1:
          setStepper(pasoFirmaFlujo1)
          break
        case 2:
          if (data.asegurados.length - 1 > 0) {
            setStepper(pasoFirmaCAF2)
          } else {
            setStepper(pasoFirmaSAF2)
          }
          break
        case 3:
          if (data.asegurados.length - 1 > 0) {
            setStepper(pasoFirmaCAF3)
          } else {
            setStepper(pasoFirmaSAF3)
          }
          break
        default:
          break
      }
    }
  }, [data])
  return (
    <div className="container-wrapper">
      <Container maxWidth="md" style={{ height: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="mX-25"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="linea-amarilla">
              <img src={borderLineaUno} alt="" />
              <div className="text-contratacio">Contrataci&oacute;n</div>
              <div className="text-contratacion-sub ">
                Por &uacute;ltimo ay&uacute;danos con los datos de
                contrataci&oacute;n para generar la p&oacute;liza de tu seguro
                m&eacute;dico.<b> Recuerda que es 100% deducible.</b>{" "}
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="frame-cuestionario-detalle">
          <div className="mX-25 frame-cuestionario">
            <div className="frame-cuestionario-detalle">
              <Stepper stepper={stepper} />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className="padding-preguntas"
              >
                <Context.Provider value={{ isSubmitting, setIsSubmitting }}>
                  <PasoFirmatres isCtaQr={false} handleClick={handleSave} />
                </Context.Provider>
              </Grid>
            </div>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disableElevation
                disabled={isSubmitting}
                sx={{ width: "100%", p: "10px!important" }}
                onClick={handleContinue}
              >
                Enviar solicitud
              </Button>
            </Grid>
          </Grid>
        </div>
        <br></br>
      </Container>
    </div>
  )
}

export default PasoFirmaMobile
