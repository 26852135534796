import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-material-ui-carousel';
import { useMediaQuery, useTheme } from '@mui/material';

import { SlideItem } from './SlideItem';
import BannerDesk from '../../assets/landing/BannerCS-10May(1440x600).webp';
import BannerFeb1 from "../../assets/landing/interBannerFebrero.webp";
import BannerTablet from '../../assets/landing/BannerCS-10May(768x800).webp';
import BannerMobile from '../../assets/landing/BannerCS-10May(360x600).webp';
import BannerMobile2 from "../../assets/landing/interMobileBanner.webp";

const items= [
  {
    image: BannerDesk,
  },
//   {
//     image: BannerFeb1
//   }
];

const itemsTablet = [
  {
    image: BannerTablet,
  },
//   {
//     image: BannerFeb1
//   }
]

const itemsMobile = [
  {
    image: BannerMobile,
  },
//   {
//     image: BannerMobile2
//   }
]

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0
  }
}));

export const SlideShow = ({ clickCotizacionAction }) => { 
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [banners, setBanners] = useState(items)
  const [height, setHeight] = useState('600px')

  React.useEffect(() => {
    if(!isTablet && !isMobile) {
      setBanners(items)
      setHeight('600px')
    }
    if(isTablet) {
      setBanners(itemsTablet)
      setHeight('650px')
    }
    if(isMobile) {
      setBanners(itemsMobile)
      setHeight('600px')
    }
  }, [isTablet, isMobile])
  
  return (
    <div className={classes.root} onClick={clickCotizacionAction}>
      <Carousel
        swipe
        fullHeightHover
        indicators={true}
        height={height}
        indicatorIconButtonProps={{
            style: {
                marginTop: isTablet ? '0px' : '30px'
            }
        }}
      >
        {banners.map((item, i) => <SlideItem key={i} item={item}/>)}
      </Carousel>
    </div>
  );
};
