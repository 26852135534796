/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  Button
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Form, Formik } from "formik";
import { validaResponse } from "../../Utils/Utils";
import { EMAIL_INCORRECTO, INTER_CONTRATANTE, TELEFONO_INVALIDO } from "../../Utils/Constantes";
import Api from "../../Api/Api";
import Loading from "../../Commons/Loader";
import { useNavigate } from "react-router-dom";
import { handleMaxLength } from "../../Utils/FieldValidationFunctions";
import CustomTextField from "../../CustomFields/CustomTextField";
import { validationForm } from "../ValidationForm";
import CustomErrorMessage from "../../CustomFields/CustomErrorMessage";
import { useScoreLead } from "../../../hooks/useScoreLead.jsx";

export default function FormNotQuotationNumber() {
  const [openLoader, setOpenLoader] = React.useState(false);
  const {device} = useScoreLead()
  const navigate = useNavigate();

  const galloMediaStep = ({correo}) => {
    window.dataLayer.push({
      'event': "form_step_0_yaTengoCot_submit",
      'eventCategory': 'Formulario.Seguro.GMM.v2',
      'eventAction': 'step_0_yaTengoCot_submit',
      'eventLabel': 'yaTengoCotizacion',
      'noCotizacion': undefined,
      'correo': correo || undefined
    })
  }

  const handleContinuar = (values, setSubmitting) => {
    let contratante = {}; 
    if (sessionStorage.getItem(INTER_CONTRATANTE)) {
      contratante = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
    }
    contratante = {
      nombre: values.nombre,
      telefono: values.telefono,
      correo: values.correo,
      ultimoPaso: 0,
      utm: "https://" + window.location.hostname + "/" + "?utm_source=" + window.localStorage.getItem('utm_source') + "&utm_medium=" + window.localStorage.getItem('utm_medium') + "&utm_campaign=" + window.localStorage.getItem('utm_campaign')+ "&utm_content=" + window.localStorage.getItem('utm_content'),
      dispositivo: device
    };
    window.sessionStorage.setItem(
      INTER_CONTRATANTE,
      JSON.stringify(contratante)
    );
    window.dataLayer.push({
      event: "form_step_0_Submit",
      name: values.nombre,
    });
    window.dataLayer.push({
      event: "medico_seguro_contacto",
      tipo_cotizacion: "Nueva cotizacion",
      cotizacion: "No aplica",
      boton_contacto: "Estoy listo"
    })
    inicioLead(contratante, setSubmitting);
  };

  const inicioLead = (contratante, setSubmitting) => {
    Api.guardaContratante(contratante)
      .then((res) => {
        if (validaResponse(res)) {
          Api.getContratante(res.data.folio)
            .then((res) => {
              window.sessionStorage.setItem(
                INTER_CONTRATANTE,
                JSON.stringify({...res.data.contratante, dispositivo: device})
              );
              setSubmitting(false);
              setOpenLoader(false);
              navigate("/Bienvenida");
            })
            .catch((error) => {
              setSubmitting(false);
              setOpenLoader(false);
            });
        } else {
          setSubmitting(false);
          setOpenLoader(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setOpenLoader(false);
      });
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          nombre: "",
          telefono: "",
          correo: "",
          terminos: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          galloMediaStep(values)
          setSubmitting(true);
          setOpenLoader(true);
          handleContinuar(values, setSubmitting);
        }}
        validationSchema={validationForm}
      >
        {({
          submitForm,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1.5rem 1rem",
              }}
            >
              <Grid item>
                <h2
                  style={{
                    margin: 0,
                    fontSize: "clamp(18px, 2.6vw, 24px)",
                    textAlign: "center",
                  }}
                >
                  ¡Primero lo primero!
                </h2>
              </Grid>
              <Grid item>
                <h2
                  style={{
                    marginTop: 0,
                    fontWeight: "normal",
                    fontSize: "clamp(18px, 2.6vw, 24px)",
                    textAlign: "center",
                  }}
                >
                  Ayúdanos con unos datos para poder brindarte una cotización
                </h2>
              </Grid>
              <Grid
                item
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  rowGap: "24px",
                }}
              >
                <Grid
                  item
                  container
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{ display: "flex", width: "100%", flexDirection: 'column' }}
                  paddingRight={"24px"}
                >
                  <Typography
                    sx={{
                      paddingBottom: "20px",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    ¿Cuál es tu primer nombre?
                  </Typography>
                  <CustomTextField
                    id="nombre"
                    name="nombre"
                    type="text"
                    placeholder="Sólo tu nombre"
                  />
                </Grid>
                <Grid item container md={8} lg={8} xl={8}>
                  <Grid item>
                    <Typography
                      sx={{
                        paddingBottom: "20px",
                        fontWeight: 700,
                        fontSize: "12px",
                      }}
                    >
                      Déjanos tus datos de contacto por si necesitas ayuda
                      (¡prometemos no darte lata!&#128521;)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      rowGap: "24px",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid item xs={12} md={6} lg={6} paddingRight={"24px"}>
                      <CustomTextField
                        id="telefono"
                        name="telefono"
                        type=""
                        placeholder="Teléfono"
                        onChange={(event) => {
                          handleMaxLength(event, setFieldValue, "telefono", 10);
                        }}
                        validate={(value) => validatePhoneNumber(value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} paddingRight={"24px"}>
                      <CustomTextField
                        id="correo"
                        name="correo"
                        type="text"
                        placeholder="Correo electrónico"
                        validate={(value) => validateEmail(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                sx={{
                  marginTop: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  columnGap: "24px",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  lg={12}
                  xl={6}
                  sx={{ display: "flex", alignItems: "center", marginBottom: '1.5rem' }}
                >
                  <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <Checkbox
                      id="terminos"
                      name="terminos"
                      onChange={handleChange}
                      style={
                        errors.terminos && touched.terminos
                          ? { color: "#CD0505", padding: "0 9px 0 0" }
                          : { padding: "0 9px 0 0" }
                      }
                    />
                    <Typography sx={{ fontSize: "14px" }}>
                      Acepto{" "}
                      <a
                        href="https://inter.mx/aviso-de-privacidad" target="_blank" 
                        style={{ color: "#039ECC", textAlign: "center" }}
                      >
                        Aviso de Privacidad
                      </a>{" "}
                      y{" "}
                      <a
                        href="https://inter.mx/terminos-condiciones" target="_blank" 
                        style={{ color: "#039ECC", textAlign: "center" }}
                      >
                        Términos y Condiciones
                      </a>
                    </Typography>
                  </Grid>
                  <CustomErrorMessage name="terminos" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  xl={5}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={submitForm}
                    disabled={isSubmitting}
                    disableElevation
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      height: "48px",
                      width: "min(100%, 350px)",
                    }}
                  >
                    ¡Estoy list@!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </>
  );
}

function validatePhoneNumber(phoneNumber){
  let error = TELEFONO_INVALIDO

  if(isThereFourConsecutiveNumber(phoneNumber)) return error
  if(allEqual(Array.from(phoneNumber))) return error
  if(phoneNumber.includes('123456789') || phoneNumber.includes('987654321')) return error

  return ""
}

function allEqual(arr){
  arr.every((val) => val === arr[0])
}

function isThereFourConsecutiveNumber(phoneNumber){
  if (/(^|(.)(?!\2))(\d)\3{4,}(?!\3)/.test(phoneNumber)) {
    return true;
  }
  return false;
}

function validateEmail(email){
  const [beforeAt, afterAt] = email.split('@')
  const blacklist = ['gmail.mx', 'hotmail.mx', 'aol', 'hotmail.com.mx', 'gmail.com.mx', 'live.com.mx', 'gmail.co', 'ensamble.dev', 'outloot.com', 'yahoo.com.sg', 'yopmail.com']
  if(beforeAt.length <= 2) return EMAIL_INCORRECTO
  if(blacklist.includes(afterAt)) return EMAIL_INCORRECTO

  return ''
}