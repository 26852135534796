import * as React from 'react';
import './Cuestionario.css';
import borderLineaDos from '../../assets/img/cuestionario/border-rounded-linea2.svg';
import { Grid, Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, Formik } from "formik";
import { validationPasoDos } from "./ValidationForm.jsx"
import CustomTextField from "../CustomFields/CustomTextField";
import Api from '../Api/Api'
import { INTER_CONTRATANTE, TIPO_FLUJO } from '../Utils/Constantes';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../Commons/Loader';

export default function PasoDos(props) {
  const[tipoFlujo, setTipoFlujo] = useState(0);
  const [openLoader, setOpenLoader] = React.useState(false);

  const handleGuardar = (values, setSubmitting) => {
        window.dataLayer.push ({
          'event': 'form_step_6_submit'
        });
    props.asegurado.peso = Number(values.peso);
    props.asegurado.altura = Number(values.estatura);
    let idContratante = null;
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
      idContratante = contratante.id
    }
    Api.actualizaAsegurado(idContratante, props.asegurado)
    .then(res => {
      setOpenLoader(false);
      props.siguienteAsegurado(props.asegurado.indice);
    })
    .catch(error => {
      setSubmitting(false);
      console.log(error);
    });
  };

  useEffect(() => {
        window.dataLayer.push ({
          'event': 'form_step_6_view'
        });
    if (window.sessionStorage.getItem(TIPO_FLUJO)) {
      setTipoFlujo(JSON.parse(sessionStorage.getItem(TIPO_FLUJO)));
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);
  
  return (
    <>
      <Accordion disabled={!props.disabled} square={true} expanded={props.disabled}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="linea-amarilla">
              <img src={borderLineaDos} alt='' />
              <div className="titular">{ props.asegurado.titular ? "Titular" : "Asegurado adicional" }</div>
              <div className="nombre">{props.asegurado.nombre}</div>
            </div>
            <hr className="divider"/>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Formik validateOnChange={true} innerRef={props.formRef}
            initialValues={{
              peso: (props.asegurado && props.asegurado.peso ? props.asegurado.peso:""),
              estatura: (props.asegurado && props.asegurado.altura ? props.asegurado.altura:"")
            }}
            onSubmit={(values, { setSubmitting }) => {
              setOpenLoader(true);
              handleGuardar(values, setSubmitting);
            }}
            validationSchema={validationPasoDos}>
            {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
              <Form>
                <Box sx={{ mt: 0 }}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {
                            props.asegurado.titular && tipoFlujo !== 3 ? 
                            <span>&iquest;Cu&aacute;nto pesas? (&iexcl;Sin pena! Nadie es perfect&#64;.)</span>
                            :
                            <span>&iquest;Cu&aacute;nto pesa? (&iexcl;Sin pena! Nadie es perfect&#64;.)</span>
                          }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ mt: 1 }}>
                        <CustomTextField id="peso" name="peso" type="text" placeholder="Peso (Kgs)" className="grid-item-input"/>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ mt: 3 }}>
                          {
                            props.asegurado.titular && tipoFlujo !== 3? 
                            <span>Y, &iquest;cu&aacute;nto mides?</span>
                            :
                            <span>Y, &iquest;cu&aacute;nto mide?</span>
                          }
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ mt: 1 }}>
                        <CustomTextField id="estatura" name="estatura" type="text" placeholder="Estatura (Cms)" className="grid-item-input"/>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {props.muestraGuardar &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100 mt-15">
                    <Button variant="outlined" disableElevation
                      className="boton-submit boton-form-seleccionado" onClick={submitForm}
                      > GUARDAR
                    </Button>
                  </Grid>
                }
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>
  );

}