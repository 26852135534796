import React from "react";
import { Box, Typography } from '@mui/material';
import './styles.css';
import CheckUp from "../../../assets/img/CheckUp.svg";
import Maternidad from "../../../assets/img/Maternidad.svg";
import Medicos from "../../../assets/img/Medicos.svg";
import PlanDental from "../../../assets/img/PlanDental.svg";

const cards = [
    {
      title: 'Maternidad',
      description: '¿Ya recibiste la gran noticia? Estarás respaldada con los gastos de maternidad, siempre y cuando estés asegurada antes del embarazo.',
      image: Maternidad
    },
    {
      title: 'Check Up Básico',
      description: '¿Necesitas exámenes médicos? Están incluidos para que puedas identificar y prevenir enfermedades como diabetes, cáncer, obesidad, entre otras.',
      image: CheckUp
    },
    {
      title: 'Médicos cerca de ti',
      description: '¿Necesitas un doctor? Podrás visitar a un especialista que este dentro de tu red médica  y sólo pagarás un porcentaje del total de la consulta.',
      image: Medicos
    },
    {
      title: 'Plan Dental',
      description: '¿Te hace falta ir al dentista? Tendrás beneficios adicionales como: Consultas, radiografías, endodoncias, entre otros.',
      image: PlanDental
    }
  ]

const SlideInicio = () => {
  const gallerySlider = document.getElementById('gallerySliderContainer');
  const sliderTotalWidth =
    (gallerySlider?.scrollWidth || 0) - (gallerySlider?.clientWidth || 0);
  const numberOfCards = cards.length;
  const sectionWidth = sliderTotalWidth / numberOfCards;

  gallerySlider?.addEventListener('scroll', (e) => {
    const scrollLeft = gallerySlider?.scrollLeft;

    cards.forEach((e, i) => {
      if (
        sectionWidth * i <= scrollLeft &&
        scrollLeft <= sectionWidth * (i + 1)
      ) {
        document.getElementById(`indicator-${i}`)?.classList.add('selected');
      } else {
        document.getElementById(`indicator-${i}`)?.classList.remove('selected');
      }
    });
  });

  let pos = { left: 0, x: 0 };

  const mouseMoveHandler = (e) => {
    const dx = e.clientX - pos.x;

    if (gallerySlider !== null) {
      gallerySlider.scrollLeft = pos.left - dx;
    }
  };

  const mouseUpHandler = () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    if (gallerySlider !== null) {
      gallerySlider.style.cursor = 'grab';
      gallerySlider.style.removeProperty('user-select');
    }
  };

  const mouseDownHandler = (e) => {
    pos = {
      left: gallerySlider?.scrollLeft || 0,
      x: e.clientX,
    };

    if (gallerySlider !== null) {
      gallerySlider.style.cursor = 'grabbing';
      gallerySlider.style.userSelect = 'none';
    }

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const handleClickIndicator = (indicatorNumber) => {

    if (gallerySlider !== null) {
      if (indicatorNumber + 1 === numberOfCards) {
        gallerySlider.scrollLeft = sliderTotalWidth
      } else {
        gallerySlider.scrollLeft = indicatorNumber * sectionWidth + 1
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F2FAFC',
        padding: '40px clamp(40px, 10vw, 120px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 0
      }}
    >
      <div
        className="gallery-slider-container"
        id="gallerySliderContainer"
        onMouseDown={mouseDownHandler}
      >
        <div className="gallery-slider" id="gallery">
          {cards.map((card, id) => (
            <div key={id} className="card">
              <div className="card-content">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
              <div className="card-image">
                <img src={card.image} alt={card.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="indicator-slider-container">
        {cards.map((e, i) => (
          <div key={i} id={`indicator-${i}`} className={`indicator ${i === 0 ? 'selected' : ''}`} onClick={() => handleClickIndicator(i)}></div>
        ))}
      </div>
    </Box>
  );
};

export default SlideInicio;
