//React//
import * as React from "react";
import "./PlanesCotizacion.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { calcularEdad } from "../Utils/Utils";
import Api from "../Api/Api";

//CSS  Logos
import AxaSeguro from "../../assets/img/planesCotizacion/logo-axa.svg";
import GDSSeguro from "../../assets/img/planesCotizacion/logo-general-de-salud.svg";
import gnp from "../../assets/img/planesCotizacion/logo-gnp.svg";
import mapfre from "../../assets/img/planesCotizacion/logo-mapfre.svg";
import TooltipIcon from "../../assets/img/planesCotizacion/TooltipIsolated.svg";

///Material
import { Grid, Button } from "@mui/material";
import { Paper, Divider, Tooltip, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

/*Const Funciones*/

function FormatNumber({ number }) {
  return (
    <span>
      {new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      }).format(number)}
    </span>
  );
}

//Style//

const icoTooltip = {
  maxWidth: "30px",
};

const contenidoTarjetas = {
  width: "200px",
  borderRadius: "6px",
  margin: "5% 21px 5% 7%",
};
const txtCoberturas = {
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0em",
  textAlign: "center",
  marginTop: "40px",
  marginBottom: "40px",
};

export default function TarjetasPlanesCotizacionDesktop(props) {
  var cadenaPreParseada = "";
  const dataObj = JSON.parse(sessionStorage.getItem("interContratante"));
  const dataTitular = dataObj.asegurados.find(
    (asegurado) => asegurado.titular === true
  );
  const navigate = useNavigate();
  const edad = calcularEdad(
    new Date(
      dataObj.tipoFlujo === 3
        ? dataTitular.fechanacimiento
        : dataObj.fechanacimiento
    )
  );
  const dates = cadenaPreParseada;

  var fechaParseada = "";
  if (dataObj["tipoFlujo"] === 3) {
    fechaParseada =
      dataTitular.fechanacimiento != null
        ? format(Date.parse(dataTitular.fechanacimiento), "dd/MM/yyyy")
        : "";
  } else {
    fechaParseada =
      dataObj.fechanacimiento != null
        ? format(Date.parse(dataObj.fechanacimiento), "dd/MM/yyyy")
        : "";
  }

  const slctPLan =
    sessionStorage.getItem("selectedPlan") !== null
      ? sessionStorage.getItem("selectedPlan")
      : "Esencial";
  const [phone, setPhone] = React.useState(dataObj.telefono);

  const evaluacionCoberturas = (
    objeto,
    nombreProp,
    valorVacio,
    nombreAseguradora
  ) => {
    if (
      nombreProp === "Coaseguro" &&
      nombreAseguradora != "GENERAL DE SEGUROS"
    ) {
      return objeto.find(
        (elementosDetalles) => elementosDetalles.nombre === nombreProp
      )
        ? objeto.find(
            (elementosDetalles) => elementosDetalles.nombre === nombreProp
          ).valor *
            100 +
            "% " +
            objeto
              .find(
                (elementosDetalles) => elementosDetalles.nombre === nombreProp
              )
              .etiquetavalor.toLowerCase()
        : valorVacio;

      // } else if (nombreProp === "Coaseguro" && nombreAseguradora == "AXA") {
      //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
    } else if (
      nombreProp === "Coaseguro" &&
      nombreAseguradora == "GENERAL DE SEGUROS"
    ) {
      return objeto.find(
        (elementosDetalles) => elementosDetalles.nombre === nombreProp
      )
        ? objeto.find(
            (elementosDetalles) => elementosDetalles.nombre === nombreProp
          ).valor *
            100 +
            "% "
        : valorVacio;

      // } else if (nombreProp === "Coaseguro" && nombreAseguradora == "AXA") {
      //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
    } else {
      return objeto.find(
        (elementosDetalles) => elementosDetalles.nombre === nombreProp
      )
        ? new Intl.NumberFormat("ES-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
          }).format(
            objeto.find(
              (elementosDetalles) => elementosDetalles.nombre === nombreProp
            ).valor
          )
        : valorVacio;
    }
  };
  const evaluacionBeneficiosAdicionales = (objeto, nombreAseguradora) => {
    let contadorBeneficios = 0;
    objeto.forEach((cadaDetalle) => {
      if (cadaDetalle.agrupador === "PLUSADICIONAL") {
        contadorBeneficios++;
      }
    });

    return contadorBeneficios;
  };

  const handleClickLoQuiero = (data) => {
    let auxObj = {
      plan: props.plan ? props.plan : "Esencial",
      aseguradora: data.nombreAseguradora,
      idAseguradora: data.id,
      costo: data.sumaTotal,
      sumaAsegurada: data.paquetes[0].detalles.find(
        (cadaDetalle) => cadaDetalle.nombre === "Suma Asegurada"
      )
        ? data.paquetes[0].detalles.find(
            (cadaDetalle) => cadaDetalle.nombre === "Suma Asegurada"
          ).valor
        : null,
      deducible: data.paquetes[0].detalles.find(
        (cadaDetalle) => cadaDetalle.nombre === "Deducible"
      )
        ? data.paquetes[0].detalles.find(
            (cadaDetalle) => cadaDetalle.nombre === "Deducible"
          ).valor
        : null,
      coaseguro: data.paquetes[0].detalles.find(
        (cadaDetalle) => cadaDetalle.nombre === "Coaseguro"
      )
        ? data.paquetes[0].detalles.find(
            (cadaDetalle) => cadaDetalle.nombre === "Coaseguro"
          ).valor
        : null,
      topeCoaseguro: data.paquetes[0].detalles.find(
        (cadaDetalle) => cadaDetalle.nombre === "Tope Coaseguro"
      )
        ? data.paquetes[0].detalles.find(
            (cadaDetalle) => cadaDetalle.nombre === "Tope Coaseguro"
          ).valor
        : null,
    };

    window.dataLayer.push({
      event: "form_step_3_submit",
      plane: props.plan ? props.plan : "Esencial",
      modalidad_pago: "Anual",
      seguro: data.nombreAseguradora,
      //'nombre': name,//
      teléfono: phone,
    });

    window.dataLayer.push({
      event: "medico_seguro_precontratar",
      Cotizacion: dataObj.folio,
      sccion_cotizacion: "Enviar Cotización",
      plan_seguro: props.plan,
      oferta: data.nombreAseguradora,
      costo_anual: data.sumaTotal,
      modalidad_pago: "Anual",
      boton_cotizacion: data.nombreAseguradora,
    });

    sessionStorage.setItem("detalleCotizacion", JSON.stringify(auxObj));
    navigate("/detalles-cotizacion");
  };

  return (
    <Grid sx={{ margin: "8px 0 0 0" }} className="hideOnMobile hideOnTablet">
      <Box>
        <Grid container spacing={0}>
          <Grid
            item
            md={2}
            sx={{
              marginTop: "267px",
            }}
          >
            {/* //Tooltips// */}

            <Stack
              direction="row"
              spacing={2}
              className={edad >= 50 ? "marginTop-152" : ""}
            >
              <Stack spacing={5}>
                <Grid>Suma asegurada</Grid>
                <Grid>Deducible </Grid>
                <Grid>Coaseguro </Grid>
                <Grid>Tope Coaseguro </Grid>
                <Grid>Beneficios </Grid>
              </Stack>
              <Stack direction="column" spacing={4.7}>
                <Tooltip title="Es la cantidad máxima de dinero que paga la aseguradora en caso de que tengas un accidente o enfermedad.">
                  <img alt="ico-tooltip" src={TooltipIcon} sx={icoTooltip} />
                </Tooltip>
                <Tooltip title="Es el monto inicial fijo que debes cubrir para poder utilizar tu seguro en cada accidente o enfermedad.">
                  <img alt="ico-tooltip" src={TooltipIcon} sx={icoTooltip} />
                </Tooltip>

                <Tooltip title="Es el porcentaje adicional que pagarás al descontar el deducible de tu accidente o enfermedad.">
                  <img alt="ico-tooltip" src={TooltipIcon} sx={icoTooltip} />
                </Tooltip>

                <Tooltip title="Es la cantidad máxima de dinero que deberás pagar de tu coaseguro por cada padecimiento.">
                  <img alt="ico-tooltip" src={TooltipIcon} sx={icoTooltip} />
                </Tooltip>
                <Tooltip title="Son las coberturas extras incluidas por la aseguradora que no tienen costo para ti.">
                  <img alt="ico-tooltip" src={TooltipIcon} sx={icoTooltip} />
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={10}
            spacing={5}
            sx={{
              textAlign: "center",
              maxWidth: "220px",
              display: "flex",
            }}
          >
            {/* //Tarjetas// */}

            {props.planesData.map((eachContent, idx) => (
              <Stack
                direction="column"
                sx={{
                  marginTop: "2%",
                }}
              >
                <Paper sx={contenidoTarjetas} key={eachContent.id}>
                  <Grid>
                    <img
                      alt="logo-aseguradora"
                      src={
                        eachContent.nombreAseguradora === "MAPFRE"
                          ? mapfre
                          : eachContent.nombreAseguradora === "AXA"
                          ? AxaSeguro
                          : eachContent.nombreAseguradora === "GNP"
                          ? gnp
                          : GDSSeguro
                      }
                      className="img-aseguradoras"
                    />
                  </Grid>
                  <Divider />
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "20px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    Costo anual:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      textAlign: "center",
                      color: "#212121",
                      fontWeight: "600",
                    }}
                  >
                    <FormatNumber number={eachContent.sumaTotal} />
                  </Typography>
                  <Grid>
                    <Button
                      onClick={() => handleClickLoQuiero(eachContent)}
                      sx={{
                        width: "180px",
                        height: "50px",
                        borderRadius: "8px",
                        backgroundColor: "#039ecc",
                        border: "none",
                        color: "#fff",
                        marginTop: "15px",
                      }}
                    >
                      ¡Lo quiero!
                    </Button>
                  </Grid>
                  {edad >= 50 ? (
                    <>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        className="espaciadoAvisos"
                      >
                        {eachContent.nombreAseguradora ===
                        "GENERAL DE SEGUROS" ? (
                          <div className="contenedorAvisosImportantes">
                            <div className="tituloAvisosImportantes">
                              Aviso importante
                            </div>
                            <br />
                            <div className="contenidoAvisosImportantes">
                              Para asegurados con 50 años o más, se realizará
                              examen médico en la red sin costo para el usuario
                            </div>
                          </div>
                        ) : eachContent.nombreAseguradora === "MAPFRE" &&
                          edad >= 58 ? (
                          <div className="contenedorAvisosImportantes">
                            <div className="tituloAvisosImportantes">
                              Aviso importante
                            </div>
                            <br />
                            <div className="contenidoAvisosImportantes">
                              Para asegurados con 58 años o más, se solicitarán
                              exámenes médicos con costo para el usuario
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                  <Stack>
                    <Grid>
                      <Typography sx={txtCoberturas}>
                        {evaluacionCoberturas(
                          eachContent.paquetes[0].detalles,
                          "Suma Asegurada",
                          "No incluido"
                        )}
                      </Typography>
                      <Typography sx={txtCoberturas}>
                        {evaluacionCoberturas(
                          eachContent.paquetes[0].detalles,
                          "Deducible",
                          "Sin deducible"
                        )}
                      </Typography>
                      <Typography sx={txtCoberturas}>
                        {evaluacionCoberturas(
                          eachContent.paquetes[0].detalles,
                          "Coaseguro",
                          "No incluido",
                          eachContent.nombreAseguradora
                        )}
                      </Typography>
                      <Typography sx={txtCoberturas}>
                        {evaluacionCoberturas(
                          eachContent.paquetes[0].detalles,
                          "Tope Coaseguro",
                          "No incluido"
                        )}
                      </Typography>
                      <Grid className="txt-coberturas text-center font-weight-700">
                        {evaluacionBeneficiosAdicionales(
                          eachContent.paquetes[0].detalles,
                          eachContent.nombreAseguradora
                        ) > 0 ? (
                          <Box sx={{ height: "50px" }}>
                            <Stack
                              justifyContent="space-evenly"
                              className="etiqueta-beneficios-adicionales"
                            >
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "20px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {evaluacionBeneficiosAdicionales(
                                  eachContent.paquetes[0].detalles,
                                  eachContent.nombreAseguradora
                                )}{" "}
                                Incluidos
                              </Typography>
                            </Stack>
                          </Box>
                        ) : (
                          <Box sx={{ height: "50px" }}>No incluidos</Box>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Paper>
              </Stack>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
